import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
// Components
import PageHero from '../components/PageHero';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

//
import './Hardware--Computing.scss';

// Video
import SecurityCam from "../vid/NetworkCameras.mp4"


function HardwareComputing() {
  return (
    <div className="hardware__content computing__content">
    <Section className="computing  light-grey-bg">
        <Container>
          <Tabs>
            <Row>
                <Col xs={12} md={4}>
                  <h3 className="">Increase productivity with our <span className="blue-text">reliable computing</span> devices and accessories</h3>

                </Col>
              <Col xs={12} md={4}>
                <h4>Mobile computing</h4>
                <p>We offer a wide range of barcode scanning solutions. Our options include handheld scanners, ruggedized mobile computers, hands free scanners, industrial scanners, scanner accessories, and more.</p>
              </Col>   
              
              <Col xs={12} md={4}>
                <h4>RFID readers</h4>
                <p>Radio Frequency Identification (RFID) is an emerging technology, especially for inventory centric businesses and those looking to keep close track of their assets. Our RFID solutions include handheld or fixed readers, RFID printers, RFID antennas, RFID tags and labels.</p>
              </Col>    
              
            </Row>
          </Tabs>
        </Container>
      </Section>
      
       <Section className="cctv">
              <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={6}>
                    <Col xs={12} lg={10} >
                      <h3 className="">Cloud-based video security that works for <span className="blue-text">all teams and environments</span> </h3>
                      <p className="">Get the latest hardware that is smart, secure, and easy to manage with Accucode’s award-winning 24/7 support. We offer security cameras that are the new standard in enterprise physical security. Stay ahead of emerging threats with ongoing features and security updates.</p>
                    </Col>
                    <Row>
                      <Col xs={12} md={6}>
                        <h4>Free consultation</h4>
                        <p>Speak with our experts for a consultation on which solution suits you best.</p>
                      </Col>
                          
                      <Col xs={12} md={6}>
                        <h4>Hardware procurement</h4>
                        <p>Our team will source all the hardware you need-- you can just sit back and relax.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Configuration services</h4>
                        <p>Once we have the hardware in hand, we will configure it all for you according to your needs.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Delivery & installations</h4>
                        <p>With everything configured, we can either ship directly to you, or come to your door for on-site installation.</p>
                      </Col>
                      <Col xs={12} md={6}>
                        <h4>Fast & reliable WiFi</h4>
                        <p>Smart security needs fast, reliable WiFi. We can also provide that for you with the <Link>best secure networking hardware</Link> on the market.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Scales with your security needs</h4>
                        <p>Cloud-based security systems are easy to install and simple to manage, allowing you to add and remove new cameras as needed.</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 6}}>
                    <video className="cctv__video "  loop autoPlay muted >
                      <source src={SecurityCam} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              
              
            
        </Section>
        
        <Section className="pos  light-grey-bg">
          <Container className="pos__content">
            <Row className="align-items-center">
              <Col xs={12} md={12} xl={4}>
                <h3 className="">
                Whether you work in <span className="blue-text">retail</span> or <span className="blue-text">hospitality</span>, we sell all the point of sale equipment you need to get your business up and selling.</h3>
                <p>We provide complete POS bundles as well as individual peripherals ranging from POS terminals, tablets, enclosures, receipt printers, kitchen printers, retail scanners, credit card reader, cash drawers, and more.</p>

              </Col>
              <Col md={12} xl={{span:7, offset: 1}}>
                <Row>
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Keep up with customer demand</h6>
                    <p>Your customers have gotten used to the “Amazon effect”: one-click ecommerce at home. More than ever, a seamless experience in the store front is critical to business in any industry to prevent lost time and sales. Get the right POS solution for your business that will optimize your entire operation in minutes— enhancing your customer experience and your profitability without sacrificing time and resources.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={6} xl={6}>
                    <h6>Add value & comfort to your business</h6>
                    <p>From contactless to traditional checkout, mobile POS and more- reliable, secure POS solutions should balance both comfort and efficiency. Reduce error, predict problems, maximize resources, and keep your employees comfortable and confident.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Achieve multisite consistency</h6>
                    <p>Keep your many locations finely tuned with visibility no matter where or who needs it. From managing multiple locations from a central hub to keeping costs low, businesses in today’s supply chain face many roadblocks to fulfilling projects and orders. Our variety of POS solutions manage it all seamlessly with our expertise in scalability.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Award-winning services & integrations</h6>
                    <p>With over 25 years of experience as a VAR, we know which partners can provide you with POS solutions that run optimally by maximizing performance. Downtime is costly and integration is our speciality. Technology should make your business process easier with immediately visible increases in ROI.</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
  );
}

export default HardwareComputing;

import HelmetComponent from "../components/HelmetComponent";
// Components
import {Container, Row, Col, ListGroup} from 'react-bootstrap';
import Section from '../components/Section';

function Terms() {
  return (
    <div id="Terms" className="Terms">
      <HelmetComponent PageID="Terms" />
      <Section>
        <Container>
          <Row>
            <Col xs={12} md={{span: 8, offset: 2}}>

<h1 id="h-terms-conditions"><strong>Terms &amp; Conditions</strong></h1>



<p>These Terms &amp; Conditions (“Agreement”) along with the respective Letter of Quote detailing the devices and services represent the collective Agreement that is entered into by and between Accucode, Inc., a Colorado corporation with offices located at 6886 S. Yosemite St., Centennial, CO 80112 (“Accucode”), and the Client detailed in the Letter of Quote or Estimate.</p>



<p><strong>Overview</strong></p>



<p>The purpose of this Agreement is to set forth the terms and conditions under which Accucode will provide services to Client. The terms and conditions of this Agreement will govern each Party’s rights and obligations to Accucode’s provision of goods and professional services hereunder. These terms and conditions, by themselves, do not implement any transaction; Accucode and Client intend that all goods and professional services provided by Accucode to Client hereunder be implemented through a Letter of Quote</p>



<p><strong>1. Definitions</strong>.</p>



<p>1.1. “Accucode Division” refers to an Accucode Division that may be involved with providing products or services under this Agreement.</p>



<p>1.2 “Deliverables” means items delivered to Client under this Agreement, including but not limited to any articles, apparatus, materials, data, goods, hardware, documentation, including manuals and other written materials, software, including code, graphics, HTML documents, audio and/or digital video components, development tools, modules, routines, menus, methodologies, algorithms, know-how, training, and managed and professional services.</p>



<p>1.3 “Information” means all confidential, proprietary or secret information, including without limitation data, plans, programs, business plans, website plans and designs, subcontractors, and other information or material owned, possessed or used by either Client or Accucode and designated by the respective Party as confidential.</p>



<p>1.4 “Letter of Quote or Estimate” means an itemization of &nbsp;the Services and other Deliverables to be &nbsp;provided by Accucode, or an Accucode affiliate, and the prices thereof, agreed to by the Parties. Each Letter of Quote or Estimate is incorporated by reference into, and shall be deemed as part of, this Agreement. The terms of this Agreement shall govern all Letters of Quote and Estimates and services undertaken by Accucode for Client.</p>



<p>1.5 “Party” means Accucode or Client, collectively referred to as “Parties.”</p>



<p>1.6 “Services” means services to be performed by Accucode, or an Accucode Division, which are described in the respective Letter of Quote.</p>



<p><strong>2. Products &amp; Services</strong><strong>.</strong></p>



<p>2.1 Products &amp; Services. Products to be supplied or Services to be performed by Accucode shall be set forth in a Letter of Quote given to the Client as a separate document.</p>



<p>2.2 Separate Agreement. Once a purchase order is issued confirming the pricing in a given &nbsp;Letter of Quote that confirmation shall constitute a separate agreement and each Letter of Quote shall incorporate by association all of the terms and conditions of this Agreement.</p>



<p>2.3 Access to Client. Where required to perform the Services, Client shall furnish information and shall provide adequate access to Client personnel and facilities reasonably necessary for Accucode to fulfill its responsibilities under this Agreement. Any delays attributable to Client’s failure to respond to Accucode for action or information within a reasonable timeframe may extend any and all deadlines for an amount of time equal to Client’s delay.</p>



<p>2.4 Safe Work Environment. Client shall ensure that while Accucode employees, agents or contractors are on Client’s premises, said premises will comply with all applicable health, safety and environmental precautions necessary to provide a safe work environment for Accucode employees, agents, and contractors.</p>



<p>2.5 Change Order. If, during the course of work under a Letter of Quote, Client requests or Accucode recommends the inclusion of additional Services not included in the original Letter of Quote, then Accucode will endeavor, prior to effecting the changes, provide Client with the associated charges for the additional Services and any changes to the date of delivery of the Services. In those situations where additional charges have not been discussed in advance for various reasons, both parties agree in good faith to resolve any monies owed to Accucode for the additional Services provided. &nbsp;</p>



<p>2.6 Reassignment. In providing Services, Accucode (i) may re-assign and substitute personnel at any time; and (ii) has the right to use third parties in performance of the Services hereunder.</p>



<p>2.7 Reasonable Effort to Avoid Damage. Accucode shall use reasonable efforts to prevent any Deliverables from containing any viruses or other similar damaging elements, but Accucode bears no responsibility for any claim or loss resulting from any such viruses or damaging elements unless caused by Accucode’s gross negligence or willful misconduct.</p>



<p>2.8 Third Parties. Nothing in this Agreement or the associated Letter of Quote shall prevent Accucode from providing the same or similar services to third parties or the Client from hiring a third party to provide similar services.</p>



<p>2.9 3D Printed Products. Client certifies that any 3D source file it provides to Accucode does not violate any laws, rules, or regulations or any third party intellectual property rights, including copyright, patent, registered design, design right, trademark, or trade secret. Client is responsible for the accuracy of any 3D source files uploaded to Accucode and for the selection of appropriate printing materials that fit Client’s requirements. 3D Printing orders may not be canceled or modified once the product goes into production. Accucode reserves the right to terminate 3D printing orders for any reason, at any time, including infringement of third party intellectual property rights, illegality, or obscenity.</p>



<p><strong>3. Ownership, Grant of License</strong>.</p>



<p>3.1 Intellectual Property. Any intellectual property created in conjunction with the execution of it duties under this Agreement will remain the exclusive property of Accucode unless said intellectual property is a contracted work for hire and an explicit Deliverable defined in an associated Letter of Quote.</p>



<p>3.2 License to Use. Any ideas, concepts, inventions, 3D models, know-how, data processing techniques or software developed solely by Client are the exclusive property of the Client. Client grants Accucode a non-exclusive, royalty-free, irrevocable, worldwide, fully paid-up license to use such for the sole purpose of performing Accucode’s Services hereunder.</p>



<p>3.3 Authorization to Use. Client shall obtain the releases, licenses, permits or other authorization to use patented, trademarked or copyrighted materials, software code, intellectual property, or other such property belonging to third parties obtained or directed to be obtained by Client for use by Accucode in performing the Services for Client</p>



<p><strong>4. Payment</strong>.</p>



<p>4.1 Services Price List. The Products and Services Price List shall be detailed in the respective Letter of Quote. If the Client requests services outside the &nbsp;Letter of Quote, then Accucode’s Services shall be at Accucode’s then-current quoted rate.</p>



<p>4.2 Expenses. Unless otherwise specified in the Letter of Quote, Client shall reimburse Accucode for the expenses and costs incurred in connection with the Services provided, including products purchased on Client’s behalf, travel, and lodging expenses.</p>



<p>4.3 Payment. Client shall pay Accucode within thirty (30) days of the date it receives the invoice if Client has been approved for credit terms with Accucode. Payments made hereunder shall be in United States Dollars. All payments made to Accucode under this Agreement shall be exclusive of all applicable taxes, which will be paid by Client and reflected on the respective invoice. If Client has not been approved for credit terms, prepayment shall be required by Client to Accucode.</p>



<p>4.4 Delinquency. If any payment or part thereof shall become due and remain unpaid for a period in excess of thirty (30) days after the due date, Client agrees to pay Accucode, in addition to the amount unpaid, interest on such amount at a compound interest rate of one and one half percent (1.5%) per month for each month or portion thereof. Interest will be calculated from the original due date and applied for each day that all or part of the original balance remains unpaid. Such interest shall be in addition to any other rights that arise as a result of Client’s failure to make any payment due hereunder. If any payment or part thereof shall become due and remain unpaid for a period in excess of sixty (60) days after the due date, Accucode reserves the right to suspend the delivery of any future Products or Services.</p>



<p><strong>5. Confidentiality</strong><strong>.</strong></p>



<p>5.1 Confidential Information. Client and Accucode shall hold each other’s Information in confidence and not disclose such Information to any third party except as allowed under this Agreement or mutual agreement in writing. The Parties agree that access to Information shall be limited to those employees and agents that have a confidentiality obligation and have a need related to this Agreement for such Information.</p>



<p>5.2 Information Disclosure. Accucode shall have the right to disclose Client’s Information to its employees, subcontractors and vendors that have a need-to-know in connection with the respective Letter of Quote, and who are bound in writing to preserve the confidentiality of the Information.</p>



<p>5.3 Limitations to Confidential Information. Confidential Information shall not include information that (a) was in the receiving Party’s possession prior to the submission thereof by the disclosing Party, (b) is obtained by the receiving Party from a third party who is not bound by obligations of confidentiality and has a right to disclose such information, (c) is or becomes generally known or available other than by unauthorized disclosure, or (d) is independently developed by the receiving Party without access to the other Party’s Information. The burden of proof for establishing that a breach of Confidential Information has occurred outside the above exceptions shall be upon the offended Party and supported by clear and convincing evidence.</p>



<p>5.4 Notices of Disclosure. If either Party is required by order of any court or regulatory authority to disclose the other Party’s Information, then the required Party shall first give notice to the other Party if permitted by law, to allow such time for the other Party to take necessary steps to protect its Information</p>



<p><strong>6. Warranties, Disclaimers, Limitation of Liability</strong>.</p>



<p>6.1 Disclaimers. &nbsp;Accucode is not the manufacturer of any hardware and makes no warranties, express, implied, or statutory, as to any matter whatsoever, including, but not limited to, the condition of the product, its merchantability, its design, its capacity, its performance, its material, its workmanship, its fitness for any particular purpose, or that the products will meet the requirements of any laws, rules, specifications, or contracts which provide for specific apparatus or special methods. Accucode disclaims liability for design specifications and performance of any 3D printed products, including accuracy, copyright compliance, or legality. Accucode further disclaims any warranties implied by the Parties’ course of dealing, course of performance, or usage of trade.</p>



<p>Accucode disclaims any liability whatsoever for loss, damage, or injury to Client or any third parties as a result of any defects, latent or otherwise, in the products. Accucode provides the products in “AS IS, WHERE IS” condition. &nbsp;Accucode shall not be liable in any event to Client or any third party for any loss, delay, or damage of any kind, resulting from defects in the product, and Client shall seek recourse with respect to any products solely against the manufacturer.</p>



<p>6.2 Warranties. Any warranties shall be solely in accordance with the warranty extended by the manufacturers of the products, and the provider of the services. Accucode warrants its Services for a period of thirty (30) days from the date of deployment. Accucode warrants only that any 3D products printed by Accucode will substantially meet the features of the 3D source file provided by Client, subject to inherent limitations in 3D printing technology. Client maintains sole legal responsibility for the design specifications. Except for the limited warranties provided in this Section 6, and to the maximum extent permitted by applicable law, Accucode makes no other warranties with respect to the products or Services, materials or information provided under this Agreement and the respective Letter of Quote.</p>



<p>6.3 Limitation of Liability. ACCUCODE’S LIABILITY AND CLIENT’S REMEDY UNDER THE FOREGOING LIMITED WARRANTY SHALL BE LIMITED TO THE EXCHANGE OF ANY PRODUCTS WHICH ARE DETERMINED BY ACCUCODE TO BE DEFECTIVE. ACCUCODE’S MAXIMUM LIABILITY FOR WARRANTY CLAIMS IS LIMITED TO THE INVOICE PRICE OF THE DEFECTIVE PRODUCT AND/OR SERVICE. THIS WARRANTY DOES NOT APPLY TO ANY COMPONENT WHICH HAS BEEN MISUSED, DEFACED, ALTERED OR MODIFIED BY CLIENT, OR WHICH HAS BEEN SERVICED OR REPAIRED BY ANY ENTITY OTHER THAN ACCUCODE OR ONE OF ACCUCODE’S AUTHORIZED SERVICE CENTERS. NOTWITHSTANDING ANYTHING IN THIS AGREEMENT TO THE CONTRARY, IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY CONSEQUENTIAL, EXEMPLARY, INCIDENTAL OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFITS ARISING OUT OF OR IN CONNECTION WITH THE SERVICES, THE DELIVERABLES OR THIS AGREEMENT, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL THE CUMULATIVE LIABILITY OF EITHER PARTY FOR ANY BREACH OF THIS AGREEMENT REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT OR TORT (INCLUDING NEGLIGENCE) EXCEED TWO TIMES THE FEES PAYABLE BY CLIENT FOR THE SERVICES WHICH DIRECTLY GAVE RISE TO SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY DOES NOT APPLY TO INDEMNIFICATION OBLIGATIONS OR TO DAMAGES CAUSED BY GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</p>



<p>6.4 Returns and Refunds</p>



<p>6.4.1 RMA Policy</p>



<p>6.4.1(a) A Return Material Authorization (RMA) number is required for all returns.</p>



<p>6.4.1(b) A RMA number is valid for 30 days.</p>



<p>6.4.1(c) Do not write on the product box. The RMA number should be visible on the shipping label.</p>



<p>6.4.1(d) Accucode does not accept returns of printheads, barcode media (labels, tags, receipt paper, ribbon), software, custom 3D printed products, or service contracts.</p>



<p>6.4.1(e) Misships must be reported to customer service within 15 days of the invoice date.</p>



<p>6.4.1(f) Product will be returned to customer under the following circumstances:</p>



<ul><li>If a product is returned missing any components sent with the original order.</li><li>If the condition of the returned product is in any way misrepresented.</li><li>If the received product is damaged in any way.</li><li>If there is not a valid and visible RMA number on the shipping label.</li><li>If a product returned as a DOA is tested as a full functioning product.</li><li>If product is received over 30 days after the date the RMA number is issued.</li><li>If a product is not returned in the original packaging and/or box.</li></ul>



<p>6.4.1(g) Products that show signs of use are not returnable to Accucode as non-DOA.</p>



<p>6.4.1(h) Client is ultimately responsible for the condition of the returned items.</p>



<p>6.4.1(i) Client is responsible for cost incurred by Accucode due to misrepresentation of the condition of product.</p>



<p>6.4.1(j) Client is responsible for freight when returning products.</p>



<p>6.4.1(k) Product that is discontinued or obsolete from the supplier does not qualify for a return.</p>



<p>6.4.2 Non-DOA Returns Policy - All Products</p>



<p>6.4.2(a) No open box returns for non-DOA products. Product box must be clean and undamaged, with no marks of any kind. This includes writing, stamps or shipping labels, i.e. Written RMA numbers. All products must be double boxed.</p>



<p>6.4.2(b) Original manufacturer's packaging, both inside and outside, must be included. Returns must be complete with all manuals, cables, warranty cards, static bags, etc. just as the customer received them.</p>



<p>6.4.2(c) Clients have 30 days from date of purchase to request a non-DOA return for most items. The RMA # issued is valid for 30 days from issue date.</p>



<p>6.4.2(d) There will be a restocking fee on returns resulting from customer error.</p>



<p>6.4.2(e) An 18% restocking fee will be applied on all returns in which the items are not determined dead on arrival.</p>



<p>6.4.2(f) Non-DOA configured product may not be returned.</p>



<p>6.4.2(g) For clients on credit terms, credit will be applied to your account when the product is received by Accucode.</p>



<p>6.4.2(h) For clients &nbsp;on C.O.D. or credit card status, replacement product will be sent upon receipt of product by Accucode. If desired, we will send replacement product at your normal C.O.D./credit card status and you can request from the Reseller Financial Services department a refund/credit to your card when the returned product is received by Accucode.</p>



<p>6.4.2(i) Client is responsible for freight costs when returning product.</p>



<p>6.4.3 DOA Return Policy</p>



<p>6.4.3(a) If the product is tested and the problem is not duplicated, there may be a "no problem found" fee charged to the customer.</p>



<p>6.4.3(b) Products that show signs of excessive use are not returnable to Accucode as DOA.</p>



<p>6.4.3(c) For clients &nbsp;on credit terms, credit will be applied to your account when the product is received by Accucode. Replacement product will be sent in advance, freight free via UPS Ground.</p>



<p>6.4.3(d) For clients on C.O.D. or credit card status, replacement product will be sent upon receipt of product by Accucode. If desired, we will send replacement product at your normal C.O.D. or credit card status and you can request from the Financial Service department a refund or credit to your card when Accucode receives the returned product.</p>



<p>6.5 High Risk Activities. &nbsp;Products and Services are not intended for use in the operation of nuclear facilities, aircraft navigation, communication systems, or other activities in which the failure of the products or Services to attain a desired result could lead to death, personal injury, or severe physical or environmental damage. Client assumes all risks and liability for Products and Services it uses in High Risk Activities.</p>



<p><strong>7. Term &amp; Termination</strong>.</p>



<p>7.1 Agreement Term. The term of this Agreement will be for the time-frame outlined in the Letter of Quote for the duration of the services specified.</p>



<p>7.2. Termination Due to Breach. Either Party may terminate this Agreement if the other Party has breached any term of this Agreement and has not cured such breach within thirty (30) days of notice thereof.</p>



<p>7.3 Termination for Convenience with Notice. Any time after one (1) year from the Effective Date of the Client’s purchase order, either Party may terminate this Agreement or upon delivery of written notice of intent to terminate within ninety (90) days of providing the written notice to the other party.</p>



<p>7.4 Opportunity to Cure Breach. Accucode will be given thirty (30) days written notice of the intent to cancel due to failure to perform or a material deviation. &nbsp;Accucode will have thirty (30) days from the receipt of notice to cure said failure or material deviation. Acceptable resolution of said failure or material deviation will be at the sole discretion of Client. If resolved within the thirty (30)-day cure period, cancellation will not take effect.</p>



<p>7.5 Termination Due to Dissolution. This Agreement may be terminated by either Party, effective immediately and without notice, in the event of dissolution, insolvency, the institution by or against the other Party of any proceeding under the United States Bankruptcy Code, or the making by the other Party of an assignment, trust, mortgage or the like for the benefit of creditors.</p>



<p>7.6 Obligations following Termination. Client shall remain obligated to pay Accucode for all charges for Services performed and all expenses incurred up to the effective date of termination, provided that Accucode has invoiced Client no later than sixty (60) days after termination. Any monies or other items of value received, including any retainers paid, shall be deemed earned by Accucode upon receipt.</p>



<p>7.7 Remedies. Termination of this Agreement under this Section 7 shall not prevent either Party from asserting any and all remedies available to it.</p>



<p>7.8 Confidential Information. Upon termination of this Agreement under Section 7 each Party shall, at the other Party’s request, destroy or deliver to the other all copies of Confidential Information of the other Party.</p>



<p>7.9 No Refunds. Upon termination of this Agreement for any reason, Client shall not be due a refund for the remaining time period left on the initial warranty or service agreement.</p>



<p><strong>8. Indemnification</strong>.</p>



<p>8.1 Accucode Indemnification Obligation. Accucode shall indemnify, defend, and hold harmless Client, and Client’s officers, directors, employees, agents, subsidiaries, and affiliates, from and against any and all liability arising out of Accucode’s negligence or willful misconduct in connection with the performance of the Services described herein.</p>



<p>8.2 Client Indemnification Obligation. Client shall indemnify, defend, and hold harmless Accucode, and Accucode’s officers, directors, employees, agents, subsidiaries, and affiliates, from and against any claim arising out of Client’s negligence or willful misconduct, including any claims arising from the use of Products and Services in High Risk Activities. Client further agrees to indemnify Accucode from all liability for the use of 3D printed products, including intellectual property infringement claims, design specifications, and performance.</p>



<p><strong>9. Miscellaneous</strong>.</p>



<p>9.1 Force Majeure. Neither Party shall be in default or otherwise liable for any delay in or failure of its performance under this Agreement if such delays or failures arise by any reason beyond its reasonable control, including, but not limited to, any act of God, any acts of the common enemy, the elements, earthquakes, floods, fires, epidemics, riots, supply chain failure, or any act or failure to act by another party. The affected Party shall promptly inform and consult with the other Party as to any of the above causes, which in its judgment may or could be the cause of a delay in the performance of this Agreement.</p>



<p>9.2 Assignment. Neither this Agreement nor any rights or licenses granted hereunder may be assigned, transferred, pledged, hypothecated, encumbered, interest given, or disposed of in any manner by either Party, whether by operation of law, change of control, or in any other manner, without the prior written approval of the other Party.</p>



<p>9.3 Governing Law. THIS AGREEMENT AND THE LEGAL RELATIONS OF THE PARTIES HERETO SHALL IN ALL RESPECTS BE GOVERNED, CONSTRUED, AND ENFORCED IN ACCORDANCE WITH THE LAWS OF THE STATE OF COLORADO WITHOUT REGARD TO CONFLICT OF LAW PRINCIPLES.</p>



<p>9.4 Arbitration. ANY CONTROVERSY OR CLAIM ARISING OUT OF OR RELATING TO THIS AGREEMENT, OR THE BREACH OR INTERPRETATION THEREOF, SHALL BE SETTLED BY MANDATORY, BINDING ARBITRATION IN COLORADO IN ACCORDANCE WITH THE RULES OF THE AMERICAN ARBITRATION ASSOCIATION, AND JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED IN ANY COURT HAVING JURISDICTION THEREOF. SERVICE OF PROCESS BY ACCUCODE IN CONNECTION WITH ANY SUCH DISPUTE SHALL BE BINDING ON CLIENT IF SENT TO CLIENT EITHER BY EMAIL OR REGULAR MAIL AT THE ADDRESS SPECIFIED IN THIS AGREEMENT OR AT SUCH ADDRESS AS OTHERWISE SPECIFIED BY CLIENT FROM TIME TO TIME. ANY CLAIM BY CLIENT AGAINST ACCUCODE FOR ANY DISPUTE RELATING TO THIS AGREEMENT SHALL BE BROUGHT WITHIN ONE (1) YEAR AFTER ANY SUCH CAUSE OF ACTION FIRST ARISES.</p>



<p>9.5 No Solicitation. During the term of this Agreement and for a period of two (2) years thereafter, Accucode and Client each agree not to solicit or induce any employee of the other to terminate his or her employment with the other, to hire any employee of the other, or engage any subcontractor of the other, without prior written consent between the Parties.</p>



<p>9.6 Relationship of the Parties. In performing Services hereunder, Accucode will at all times be an independent Party, and this Agreement shall not constitute, or be deemed to constitute, either Party as an employee, agent or joint venture of the other.</p>



<p>9.7 Data Protection. Client agrees that it shall maintain appropriate data protection and privacy technical and organizational measures and that Client shall comply with all applicable data protection and privacy laws and regulations. &nbsp;Client further agrees to comply with any request made by Accucode to ensure compliance with applicable data protection laws.</p>



<p>9.8 Entire Agreement. The Letter of Quote along with these Terms &amp; Conditions constitute the entire, final, and complete agreement between Accucode and the Client. No modification to this Agreement shall be binding unless in writing specifically referring to this Agreement, and is signed by an authorized representative of each Party. This Agreement supersedes any prior oral or written statements, agreements, or representations with respect to the subject matter hereof.</p>



<p>9.9 Notices. All notices and demands (each a “Notice”) under this Agreement shall be given in writing and shall be delivered to the recipient and deemed received by the recipient as follows: (a) four (4) days after depositing the Notice in the mail, provided the Notice is sent using U.S. registered or certified mail, return receipt requested, (b) if sent via email during normal business hours in the time zone of the recipient (or the beginning of the next business day if sent after normal business hours), upon receipt of electronic confirmation that transmission was received, (c) upon delivery (no signature required) if deposited with an internationally recognized overnight delivery service (such as FedEx) for overnight delivery or two days after deposit if deposited for two day delivery, or (d) upon receipt if hand delivered to the individual designated below. Notices shall be sent to the recipient at the contact information contained in the respective Letter of Quote or Estimate. A Party may change its address by providing the other Party Notice in accordance with this Section.</p>



<p>9.10 Severability. Any provisions of this Agreement which are unenforceable in any jurisdiction shall, as to such jurisdiction, be ineffective to the extent of such unenforceability without invalidating the remaining provisions hereof, and any such unenforceability in any jurisdiction shall not render unenforceable such provisions in any other jurisdiction. To the extent permitted by applicable law, Client hereby waives; (a) any provisions of law which render any provision hereof unenforceable in any respect; and (b) all rights not expressly provided herein and as otherwise available under Colorado law or corresponding provisions of the Uniform Commercial Code.</p>



<p>9.11 Export Laws. Client hereby acknowledges and accepts that the Services and Deliverables herein include United States technical information and therefore are subject to the Export Administration Regulations relating to export or re-export of United States technical data, equipment, and products produced therefrom. Client agrees to fully comply with all such government regulations to the extent they apply to the Services and Deliverables made available to Client.</p>



<p>9.12 No Waiver. No failure on the part of Accucode to exercise and no delay in exercising, any right or remedy, hereby shall operate as a waiver thereof unless such waiver is in writing signed by the Party waiving its right; nor shall any single or partial exercise by Accucode of any right or remedy hereunder preclude any other or further exercise thereof or the exercise of any other right or remedy.</p>



<p>9.13 Numbers and Captions. As used herein, the singular shall include the plural, and the plural the singular. All captions used herein are intended solely for convenience of reference and shall in no way limit or explain any of the provisions of the Agreement.</p>



<p>9.14 Survival. All of the provisions of this Agreement shall survive the execution, delivery, and termination of this Agreement.</p>

              
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  );
}

export default Terms;

import React from 'react';
import {Routes} from '../Routes';
import {Container, Row, Col, ListGroup} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

//Images
import logo from "../img/accucode-logo-white.svg";

import './Footer.scss';

function Footer() {
  
 return (
  <footer className="footer">
    <Container>
      <Row className="footer__cta">
        <Col xs={12}>
          <p className="h1 text-white text-center">Let's build something great together!</p>
          <a href="/contact"><Button variant="outline-light" className="footer__cta-btn">Get started</Button></a>
        </Col>
      </Row>
      <Row className="footer__navigation">
        <Col  xs={12} sm={12} md={6} lg={6} xl={4}>
            <strong className="footer__menu-heading">Products</strong>
            <div  className="footer__menu--services">
              <ul className="footer__menu">
              {Routes.map((item, index) => {
                      return item.Parent === 'Products' && (
                        <li  className="footer__menu-item"key={index}>
                          <a href={item.Path} className="footer__menu-link" title={item.Title}>{item.Title}</a>
                        </li>
                      )
                  
                })}
              </ul>
          </div>
        </Col>
        
        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
          <div className="footer__menu--services">
            <strong className="footer__menu-heading">Services</strong>
            <ul  className="footer__menu">
              {Routes.map((item, index) => {
                      return item.Parent === 'Services' && (
                        <li  className="footer__menu-item"key={index}>
                          <a href={item.Path} className="footer__menu-link" title={item.Title}>{item.Title}</a>
                        </li>
                      )
                  
                })}
            </ul>
          </div>
        </Col>
        
        <Col xs={12} sm={12} md={6} lg={6} xl={4}>
          <div className="footer__menu--company">
            <strong className="footer__menu-heading">Company</strong>
            <ul  className="footer__menu">
              {Routes.map((item, index) => {
                      return item.Parent === 'Company' && (
                        <li  className="footer__menu-item"key={index}>
                          <a href={item.Path} className="footer__menu-link" title={item.Title}>{item.Title}</a>
                        </li>
                      )
                  
                })}
            </ul>
          </div>
        </Col>
      </Row>
      
      <hr/>
      
      <Row className="small">
        <Col className="footer__address" xs={12} md={6}>
            <ul>
              <li className="footer__company">Accucode, Inc</li>
              <li className="footer__street"><a href="https://www.google.com/maps/place/Accucode,+Inc./@39.5800246,-104.8581398,17z/data=!3m1!4b1!4m5!3m4!1s0x876c8678d1ea5055:0xbbf8e0bf8d98b000!8m2!3d39.5800205!4d-104.8559511">65 Inverness Dr. East, Englewood CO 80112</a></li>
              <li className="footer__phone"><a href="tel:3036396111">303 639 6111</a></li>
            </ul>

        </Col>
        
        <Col className="footer__legal"  xs={12} md={6}>
          <ul>
            <li><a href="/terms">Terms & Conditions</a></li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
  );
}

export default Footer


import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
// Components
import PageHero from '../components/PageHero';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


import './Hardware--Printing.scss';

// Video
import VideoStyles from '../components/video.scss';
import SecurityCam from "../vid/cctv.mp4"
function CloudSecurity() {
  return (
    <div>
      <Section className="cctv">
              <Container className="cctv__content">
                <Row>
                  <video className="cctv__video video--full-width"  loop autoPlay muted >
                    <source src={SecurityCam} type="video/mp4" />
                  </video>
                </Row>
                
                <Row className="align-items-center">
                  <Col xs={12} md={4} >
                    <p className="caps">Cloud Security</p>
                    <h3 className="">Cloud-based video security that works for <span className="blue-text">all teams and environments</span> </h3>
                    <p className="">Get the latest hardware that is smart, secure, and easy to manage with Accucode’s award-winning 24/7 support. We offer security cameras that are the new standard in enterprise physical security. Stay ahead of emerging threats with ongoing features and security updates.</p>
                    
                  
                   
                  </Col>
                  
                  <Col xs={12} md={{span:7, offset:1}}>
                    <Row>
                      <Col xs={12} md={6}>
                        <h4>Scales with your security needs</h4>
                        <p>Dolor adipiscing conubia a arcu condimentum dui sapien cubilia urna.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Free consultation</h4>
                        <p>Dolor adipiscing conubia a arcu condimentum dui sapien cubilia urna.</p>
                        
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Hardware procurement</h4>
                        <p>Dolor adipiscing conubia a arcu condimentum dui sapien cubilia urna.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Configuration services</h4>
                        <p>Dolor adipiscing conubia a arcu condimentum dui sapien cubilia urna.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Delivery & installations</h4>
                        <p>Dolor adipiscing conubia a arcu condimentum dui sapien cubilia urna.</p>
                      </Col>
                      <Col xs={12} md={6}>
                        <h4>Fast & reliable WiFi</h4>
                        <p>Dolor adipiscing conubia a arcu condimentum dui sapien cubilia urna.</p>
                      </Col>
                    </Row>
                </Col>  
                </Row>
              </Container>
              
              
            
        </Section>
        
    </div>   
  );
}

export default CloudSecurity

import React from 'react';
import SectionCSS from './Section.scss';

function Section(props) {
  const background = props.background;
  
  return (
    <section id={props.id} className={props.className + " section" }>
      <div className="section__content">
        {props.children}
      </div>
        
        {props.background &&
          <div className="section__background">
            <img className={"" + props.className+"__background"} src={background} />  
          </div>
        }
        
    </section>
  );
}

export default Section;


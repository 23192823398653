import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// Components

import PageHero from '../components/PageHero.scss';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


// Images

import InDemand from '../img/indemand-pba.svg'

// Partners
import Axis from '../img/partners/accucode-partners/axis-communications.webp'
import Cisco from '../img/partners/accucode-partners/cisco-logo.webp'
import Extreme from '../img/partners/accucode-partners/extreme-networks.webp'
import Honeywell from '../img/partners/accucode-partners/honeywell-logo.webp'
import Nokia from '../img/partners/accucode-partners/nokia.webp'
import Panasonic from '../img/partners/accucode-partners/panasonic-logo.webp'
import Verkada from '../img/partners/accucode-partners/verkada-logo.webp'
import Zebra from '../img/partners/accucode-partners/zebra-logo.webp'

// Video
import HeroBackground from "../vid/it-in-datacenter.mp4"

import '../components/LogoSlider.scss';
// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

// CSS
import Styles from './LifecycleServices.scss';


function LifecycleServicesProcurement() {
  return (
    <>
     
                
                <Section className="partners  ">
                  <Container>
                    <Row className="align-items-center">
                       <Col sm={12} md={4} lg={4}>
                          <h3>
                            Partnered with many of the top OEMs in the world, giving you <span className="blue-text">best-of-breed </span> hardware solutions 
                          </h3>
                                                  <p>Not sure which brand or model to go with? Our engineers will perform a thorough assessment of your needs and then make recommendations on the best options available for your unique needs.</p>

                        </Col>
                      <Col  sm={12} md={{span:12 }}lg={{span: 8, offset: 0}} >
                          <Swiper
                            className="logo-slider"
                            breakpoints={{
                      // when window width is >= 0px
                      0: {
                        width: 320,
                        slidesPerView: 1,
                      },// when window width is >= 320px
                      320: {
                        width: 320,
                        slidesPerView: 2,
                      },// when window width is >= 720px
                      720: {
                        width: 720,
                        slidesPerView: 3,
                      },
                      
                    }}
                            modules={[Autoplay, Navigation]}
                            spaceBetween={50}
                            slidesPerView={3}
                            autoplay
                            // navigation
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            >
                            <SwiperSlide><img src={Honeywell} /></SwiperSlide>
                            <SwiperSlide><img src={Panasonic} /></SwiperSlide>
                            <SwiperSlide><img src={Cisco}/></SwiperSlide>
                            <SwiperSlide><img src={Nokia}/></SwiperSlide>
                            <SwiperSlide><img src={Zebra}/></SwiperSlide>
                            <SwiperSlide><img src={Verkada} /></SwiperSlide>
                            <SwiperSlide><img src={Extreme} /></SwiperSlide>
                            <SwiperSlide><img src={Axis} /></SwiperSlide>
                        </Swiper>
                      </Col>
                    </Row>
                  </Container>
                </Section>
    </>
  );
}

export default LifecycleServicesProcurement;

// Pages
import Home from './pages/Home';

// Products
import Hardware from './pages/Hardware';
import HardwareComputing from './pages/Hardware--Computing';

import ForWork from './pages/4Work'

import EdgeAI from './pages/EdgeAI';
// Services

import ManagedNetworks from './pages/ManagedNetworks';
import LifecycleServices from './pages/LifecycleServices';
import ThreeD from './pages/3dPrinting';
import DigitalManufacturing from './pages/DigitalManufacturing';

// Company
import Contact from './pages/Contact';
import News from './pages/News';
import About from './pages/About';
import Careers from './pages/Careers';

// Single post template
import SinglePost from './pages/SinglePost';
import SingleJob from './pages/SingleCareer';


//
// SEO Thumbnails
//

import ThreeDThumb from './img/thumbs/3d_thumb.jpg'
import DroneThumb from './img/thumbs/drone_thumb.jpg'
import AboutThumb from './img/thumbs/about_thumb.jpg'
import EdgeAIThumb from './img/thumbs/edgeai_thumb.jpg'
import HardwareThumb from './img/thumbs/hardware_thumb.jpg'
import HomeThumb from './img/thumbs/home_thumb.jpg'
import LifecycleThumb from './img/thumbs/lifecycle_thumb.jpg'
import NetworksThumb from './img/thumbs/networks_thumb.jpg'
import WMSThumb from './img/thumbs/wms_thumb.jpg'
import NewsThumb from './img/thumbs/news_thumb.jpg'

const RootUrl = 'https://' + window.location.hostname;

const SiteInfo = {
    Title: 'Accucode',
    RootUrl: RootUrl,
    Canonical: RootUrl,
    Separator: '|'
}
const Routes = [
    {
        Parent: '',
        id: "Home",
        Thumb: HomeThumb,
        Title: "Accucode",
        SeoTitle: "Award-winning technology solutions, services & support.",
        SeoDescription: "We custom design, acquire, install, manage and support a wide variety of technologies so you can free up resources to focus on your core business.",
        Path: "/",
        Component: Home
    },
    
    {
        Parent: 'Products',
        id: "CoreHardware",
        Thumb: HardwareThumb,
        Title: "Hardware",
        SeoTitle: "Hardware solutions with enterprise-grade services & support.",
        SeoDescription: "We offer award-winning hardware solutions in networking, computing, and barcoding with best-in-class partners and enterprise-grade services & support.",
        Path: "/hardware",
        Component: Hardware
    },
    
    // Business Optimization
    {
        Parent: 'Products',
        id: "ForWork",
        Thumb: WMSThumb,
        Title: "Workforce Management Suite",
        SeoTitle: "4Work allows you to design manageable, scalable and repeatable projects with real-time visibility and results you can count on.",
        SeoDescription: "Close the skills gap and gain real-time project visibility with 4Work - our workforce optimization and project management software for technology rollouts.",
        Path: "/workforce-management-suite",
        Component: ForWork
    },

    {
        Parent: 'Products',
        id: "EdgeAI",
        Thumb: EdgeAIThumb,
        Title: "EdgeAI Micro Kiosk",
        SeoTitle: "Our multi-use kiosk and intuitive dashboard gives your business the power of AI.",
        SeoDescription: "EdgeAI Kiosk is an AI-powered micro kiosk that can be used to improve your business processes.",
        Path: "/edge-ai",
        Component: EdgeAI
    },   
    
    
    //==================
    // Services
    //==================
    {
        Parent: 'Services',
        id: "LifecycleServices",
        Thumb: LifecycleThumb,
        Title: "Lifecycle Services",
        SeoTitle: "Wholesale technology solutions for IT providers & network deployment.",
        SeoDescription: "From initial set up and design to large-scale rollouts, our turn-key services put your business in the best position to succeed.",
        Path: "/lifecycle-services",
        Component: LifecycleServices
    },
    
    {
        Parent: 'Services',
        id: "ManagedNetworks",
        Thumb: NetworksThumb,
        Title: "Managed Networks",
        SeoTitle: "Get fully cloud-managed infrastructure operational in minutes.",
        SeoDescription: "Get the security and reliability of Meraki’s powerful Extreme Network paired with Accucode’s Elite100 Managed Services in one place.",
        Path: "/managed-networks",
        Component: ManagedNetworks
    },
    
    {
        Parent: 'Services',
        id: "3dPrinting",
        Title: "3D Printing & Scanning",
        Thumb: ThreeDThumb,
        SeoTitle: "Full service 3D repairs & consulting.",
        SeoDescription: "We offer an affordable way to 3D print high-strength parts using a variety of materials.",
        Path: "/3d-printing",
        Component: ThreeD
    },
    {
        Parent: 'Services',
        id: "OperationsLogistics",
        Title: "Operations Logistics",
        Thumb: DroneThumb,
        SeoTitle: "Technology for operational logistics that drive consistent outcomes in an unpredictable market.",
        SeoDescription: "Scalability and connectivity between devices give you visibility, accurate data collection, and easy reporting for your business in any environment.",
        Path: "/digital-manufacturing",
        Component: DigitalManufacturing
    },
    
    

    

    
    // Company
    {
        Parent: 'Company',
        id: "About",
        Title: "About",
        Thumb:  AboutThumb,
        SeoTitle: "The heart of Accucode is to think outside the box and look for unique solutions to solve IT challenges.",
        SeoDescription: "The heart of Accucode is to think outside-the-box and look for unique ways to solve problems.",
      
        Path: '/about',
    },    
    {
        // Parent: 'Company',
        // id: "News",
        // Title: "News",
        // Thumb: NewsThumb,
        // SeoTitle: "News",
        // SeoDescription: "Find the latest news and updates from Accucode here.",
        // Path: "/news",
        // Component: News
    },
    {
        Parent: 'Company',
        id: "Contact",
        Title: "Contact",
        SeoTitle: "Get in touch.",
        SeoDescription: "Contact us today for a free, no obligation discovery consultation with our team.",
      
        Path: '/contact',
        Component: Contact
    },
    {
        // Parent: 'Company',
        // id: "Careers",
        // Title: "Careers",
        // SeoTitle: "Accucode careers",
        // SeoDescription: "Come join the Accucode team!",
      
        // Path: '/careers',
        // Component: Careers
    },
    
    // Legal
    
    {
        Parent: '',
        id: "Terms",
        Title: "Terms & Conditions",
        SeoTitle: "Accucode terms & conditions",
        SeoDescription: "Come join the Accucode team!",
      
        Path: '/terms',
        Component: Careers
    },
    
    // Single blog pages
    // Must be last
    {
        Parent: 'News',
        id: "SinglePost",
        Path: "/news/:slug",
        Component: SinglePost
    },
    {
        Parent: 'Careers',
        id: "SingleJob",
        Path: "/careers/:slug",
        Component: SingleJob
    },
    
    
    
]

export {Routes, SiteInfo};
// import React from 'react';
// import Section from '../components/Section.js'
// import YoutubeBackground from 'react-youtube-background';
// import {Container, Row, Col } from 'react-bootstrap';
// import HeroCSS from './PageHero.scss';

const PageHero = ({}) => {
  
}

const Card = ({ children }) => {
  return <div className="card">{children}</div>;
};

const Header = ({ children }) => {
  return <div className="card-header">{children}</div>;
};

const Body = ({ children }) => {
  return <div className="card-body">{children}</div>;
};

Card.Header = Header;
Card.Body = Body;

export default PageHero;
// function PageHero(props) {
  
//   PageHero.defaultProps = {
//       img : '',
//       alt : '',
//       cName : ''
//   };

//   return (
//     <Section className="page-hero">
//           <Container>
//             <Row className="align-items-center">
//               <Col className="page-hero__content-wrap " xl={7}>
//                   <Col className="page-hero__content " xl={10} >
//                     <h1 className=" text-white">Visibility and <span className="bold">predictable outcomes</span> every step of the way</h1>
//                     <Col lg={12} xl={10}>
//                       <h3 className="thin text-white">We work as an extension of IT infrastructures and within the scope of every project-- whether new or ongoing. </h3>

//                     </Col>
//                   </Col>
                  
//                     <div className="page-hero__background">
//                         <video className="page-hero__video"  loop autoPlay muted >
//                           <source src={HeroBackground} type="video/mp4"/>
//                         </video>
//                         <div className="page-hero__background-color"></div>
       
//                     </div>
//               </Col>
              
//               <Col xl={5}>
//                 <Row>
//                   <Col className="gy-5" sm={6} md={6} xl={6}>
//                     <h6>Workforce Automation</h6>
//                     <p>Easily find your schedule, equipment, labor, costs, and more stored in one place.</p>
//                     <Link className="rarr" to="/workforce-optimization-software"> Learn more </Link>
//                   </Col>
                  
//                   <Col className="gy-5" sm={6}md={6} xl={6}>
//                     <h6>Supply Chain Optimization</h6>
//                     <p>Remote ordering and crowdsourced delivery route optimization for your business.</p>
//                     <Link className="rarr" to="/order-management-software"> Learn more </Link>
//                   </Col>
                  
//                   <Col className="gy-5" sm={6} md={6} xl={6}>
//                     <h6>Managed Networks</h6>
//                     <p>Secure, reliable networks & Accucode’s Elite100 Managed Services in one place.</p>
//                     <Link className="rarr" to="/managed-networks"> Learn more </Link>
//                   </Col>
                  
//                   <Col className="gy-5" sm={6} md={6} xl={6}>
//                     <h6>EdgeAI Micro Kiosk</h6>
//                     <p>Our multi-use kiosk and intuitive dashboard gives your business the power of AI.</p>
//                     <Link className="rarr" to="/ai-consulting"> Learn more </Link>
//                   </Col>
//                 </Row>
       
//               </Col>
//             </Row>
//           </Container>
//         </Section>
//     // <Section className={`page-hero ${props.className}`}>
    

//     //     {props.img.length > 0 &&
//     //         <div>
//     //             <Container>
//     //                 <Row>
//     //                     <div className="page-hero__content">
//     //                         {props.children}
//     //                     </div>
//     //                 </Row>
//     //             </Container>
//     //             <img  className="page-hero__img" src={props.img}  />
//     //         </div>
//     //     }
    
//     //     {props.youtube === true && 
//     //         <YoutubeBackground videoId={props.videoId} className="page-hero__video">
//     //             <Container>
//     //                 <Row>
//     //                     <div className="page-hero__content">
//     //                         {props.children}
//     //                     </div>
//     //                 </Row>
//     //             </Container>
//     //         </YoutubeBackground>
//     //     }
//     // </Section>
//   );
// }

import HelmetComponent from "../components/HelmetComponent";
// Components
import ContactForm from '../components/forms/ContactForm';
import {Container, Row, Col, ListGroup} from 'react-bootstrap';
import Section from '../components/Section';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';

// Maps
import OfficeMap from "../components/Map";

function Contact() {
  return (
    <div id="Contact" className="Contact">
      <HelmetComponent PageID="Contact" />
      <Section>
        <Container>
          <Row>
            <Col xs={12} md={{span: 8, offset: 2}}>
              <Row>
                <h1>Let's build something great together!</h1>
              
                <Col md={7}>
                  <h3 className="thin">The first step is to chat. Fill out our form below, and we'll be in touch soon to learn more about your project!</h3>
                </Col>
              </Row>    
              <Row>
                <ContactForm />
              </Row>
              
            </Col>
            
            <Col>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  );
}

export default Contact;

import React, {useEffect} from 'react';
import {Routes, SiteInfo} from '../Routes';

import Helmet from 'react-helmet';
function HelmetComponent(props) {
    
    const PageID = props.PageID;
    const GetPageData = Routes.filter(obj => {
        return obj.id === PageID;
    })
  
    const PageData = GetPageData[0];
    
  return (
    <Helmet>
         <meta charSet="utf-8" />
        {props.title != '' && PageID === 'home' &&
            <title>{SiteInfo.Title + ' ' + SiteInfo.Separator + ' ' + PageData.SeoTitle }</title>
        }
        {PageID != 'home' &&
            <title>
                { 
                    PageData.Title + ' ' +  
                    SiteInfo.Separator + ' ' + 
                    PageData.SeoTitle  + ' ' + 
                    SiteInfo.Separator + ' ' + 
                    SiteInfo.Title 
                }
            </title>
        }
        
        {
            //
            // https://medium.com/@mpgelber7495/how-to-change-link-previews-open-graph-with-a-client-side-rendered-react-app-using-react-helmet-ab2a5e2059f7 
            //    
        }
        
        <meta 
            name="description" 
            content={PageData.SeoDescription} 
        />
        <meta 
            property="og:title"
            content={SiteInfo.Title + ' ' + SiteInfo.Separator + ' ' + PageData.SeoTitle}
        />
        
        <meta 
            name="image"
            property="og:image"
            content={SiteInfo.RootUrl +  PageData.Thumb}
        />
        <meta
            property="og:type"
            content="website"
        />
        <meta
            property="og:url"
            content={[ SiteInfo.RootUrl + '/' + PageData.Path]}
        />
        
        <meta property="twitter:card" content={[SiteInfo.RootUrl + PageData.Thumb]}/>
        <meta property="twitter:url" content={[ SiteInfo.RootUrl + PageData.Path]} />
        <meta property="twitter:title" content={SiteInfo.Title + ' ' + SiteInfo.Separator + ' ' + PageData.SeoTitle} />
        <meta property="twitter:description" content={PageData.SeoDescription} />
        <meta property="twitter:image" content={SiteInfo.RootUrl + PageData.Thumb} />
        
            
        <link rel="canonical" href={[ SiteInfo.RootUrl + '/' + PageData.Path]} />
    </Helmet>  

  );
}

export default HelmetComponent;


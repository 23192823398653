import HelmetComponent from "../components/HelmetComponent";
// Components

import PageHero from '../components/PageHero';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Icon from '../components/Icon';
import {Container, Row, Col } from 'react-bootstrap';

// css
import Styles from './ManagedNetworks.scss';

//Lottie 
import Lottie from "lottie-react";
import PageNotFound from '../img/lottie/lonely-404.json';


function FourOhFour() {
  return (
    <div className="404">
      <Section>
        <Container>
          <Row>
          
                <Lottie animationData={PageNotFound} />
          </Row>
        </Container>
      </Section>
    </div>
  );
}

export default FourOhFour;

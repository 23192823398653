import HelmetComponent from "../components/HelmetComponent";
import React from 'react';
import { Link } from 'react-router-dom';


// Components


import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';


import Separator from "../components/Separator"
import { Parallax } from 'react-parallax';
import Section from '../components/Section.js'
import SectionIntro from '../components/SectionIntro.js'


// import PageHero from '../components/PageHero.js'

// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import '../components/LogoSlider.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

// Import Swiper styles
// import 'swiper/css';



// CSS
//==========================
import HomeCSS from './Home.scss';

import PageHero from '../components/PageHero.scss';
import Video from '../components/video.scss';


//Video
//=====================

import QuoteBackground from '../vid/quotebg.mp4';
import HeroBackground from '../vid/warehouse.mp4'

// Images
//===========================

import TeltechMetrics from '../img/teltech__metrics.webp'
import TeltechApp from '../img/teltech__app.webp'
import TeltechTakingPhoto from '../img/teltech__takingphoto.webp'
import TeltechWarehouse from '../img/Teltech__warehouse.webp'
import QuoteIcon from '../img/quote.svg'


// Partners
import Axis from '../img/partners/accucode-partners/axis-communications.webp'
import Cisco from '../img/partners/accucode-partners/cisco-logo.webp'
import Extreme from '../img/partners/accucode-partners/extreme-networks.webp'
import Honeywell from '../img/partners/accucode-partners/honeywell-logo.webp'
import Nokia from '../img/partners/accucode-partners/nokia.webp'
import Panasonic from '../img/partners/accucode-partners/panasonic-logo.webp'
import Verkada from '../img/partners/accucode-partners/verkada-logo.webp'
import Zebra from '../img/partners/accucode-partners/zebra-logo.webp'

function Home() {
  return (
    <div className="Home">
        <HelmetComponent PageID="Home" />
        <Section className="page-hero">
          <Container>
            <Row className="">
              <Col className="page-hero__content-wrap " xl={7}>
                  <Col className="page-hero__content " xl={10} >
                    <h1 className=" text-white">Visibility and <span className="bold">predictable outcomes</span> every step of the way</h1>
                    <Col lg={12} xl={10}>
                      <h3 className="thin text-white">Over 25 years of continuous innovation, services, and solutions for business </h3>

                    </Col>
                  </Col>
                  
                  <div className="page-hero__background">
                      <video className="page-hero__video video--sidebar"  loop autoPlay muted >
                        <source src={HeroBackground} type="video/mp4"/>
                      </video>
                      <div className="page-hero__background-color"></div>
     
                  </div>
              </Col>
              
              <Col xl={5}>
                <Row>
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Managed Services</h6>
                    <Separator />
                    <p>We deliver operational efficiencies, IT system improvements, and a higher ROI for our customers. We focus on guiding business and create solutions that take weight off of your IT department.   </p>
                    <Link className="rarr" to="/lifecycle-services"> Learn more </Link>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={6} xl={6}>
                    <h6>IT Infrastructure</h6>
                    <Separator />
                    <p>Simplicity is the key to a more efficient, more secure IT ecosystem. We deliver IT services and solutions at an enterprise level- all in the cloud.</p>
                    <Link className="rarr" to="/managed-networks"> Learn more </Link>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Business Optimization</h6>
                    <Separator />
                    <p>Get predictable outcomes every time with automation to improve performance, efficiency, and innovation. Execute projects with speed and accuracy, optimize costs, and securely scale operations.</p>
                    <Link className="rarr" to="/workforce-management-suite"> Learn more </Link>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Core Technologies</h6>
                    <Separator />
                    <p>We have custom designed, acquired, installed, managed, and supported a wide variety of technologies so customers can free up resources to focus on their core businesses for over 25 years.</p>
                    <Link className="rarr" to="/hardware"> Learn more </Link>
                  </Col>
                </Row>
       
              </Col>
            </Row>
          </Container>
        </Section>
        
      <Section className="partners light-grey-bg">
          <Container>
            <Row className="align-items-center">
               <Col sm={12} md={12} lg={4}>
                  <h3>
                    An ecosystem of partners <span className="blue-text">worldwide</span>
                  </h3>
                </Col>
              <Col sm={12} md={{span:12 }}lg={{span: 8, offset: 0}}>
                  <Swiper
                    className="logo-slider"
                    modules={[Autoplay, Navigation]}
                    spaceBetween={50}
                    slidesPerView={3}
                    breakpoints={{
                      // when window width is >= 0px
                      0: {
                        width: 320,
                        slidesPerView: 1,
                      },// when window width is >= 320px
                      320: {
                        width: 320,
                        slidesPerView: 2,
                      },// when window width is >= 720px
                      720: {
                        width: 720,
                        slidesPerView: 3,
                      },
                      
                    }}
                    autoplay
                    // navigation
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide><img src={Honeywell} /></SwiperSlide>
                    <SwiperSlide><img src={Panasonic} /></SwiperSlide>
                    <SwiperSlide><img src={Cisco}/></SwiperSlide>
                    <SwiperSlide><img src={Nokia}/></SwiperSlide>
                    <SwiperSlide><img src={Zebra}/></SwiperSlide>
                    <SwiperSlide><img src={Verkada} /></SwiperSlide>
                    <SwiperSlide><img src={Extreme} /></SwiperSlide>
                    <SwiperSlide><img src={Axis} /></SwiperSlide>
                  </Swiper>
                  {
                    //
                    //<div className="swiper__nav">
                    //  <div className="swiper-button-prev"></div>
                    //  <div className="swiper-button-next"></div>
                    //</div>
                    //
                  }
              </Col>
            </Row>
          </Container>
        </Section>
        
        <Section className="quote">
          <Row className="align-items-center">
            <Col className="quote__images quote__images--left" md={12} lg={4} xl={4}>
              <img src={TeltechMetrics} />
              <img src={TeltechTakingPhoto} />
            </Col>
            
            <Col className="quote__content" md={12} lg={4}  xl={4}>
              <img className="quote__icon" src={QuoteIcon} />
              <h6 className="q__title text-center">Accucode Named in CRN’s Managed Service Provider (MSP) 500 list in the Elite Category</h6> 
              <p className="q__content h4 thin text-center">
                Accucode was chosen for its disruptive technology and custom solutions. Accucode has custom designed, acquired, installed, managed, and supported a wide variety of technologies so customers can free up resources to focus on their core businesses for 25 years. 
              </p>
              
              <Link className="rarr quote__link" to="/news/accucode-recognized-on-crns-2020-msp500-list">Read the story</Link>
            </Col>
            
            <Col className="quote__images quote__images--right" md={12} lg={4} xl={4}>
              
              <img src={TeltechWarehouse} />
              <img src={TeltechApp} />
            </Col>
          </Row>
        </Section>
        
  
        
        
    </div>
  );
}

export default Home;

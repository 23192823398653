import React from 'react';
import Styles from './SectionIntro.scss';

function SectionIntro(props) {

  return (
    <div 
      className={`section__intro ${props.className} `  } >
        {props.children}
    </div>
  );
}

export default SectionIntro;


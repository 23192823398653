 // Render Prop
import React, {useState} from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';


// CSS
import ContactFormStyles from './ContactForm.scss';
// import ThemeButtons from '../css/vars/buttons.scss';

// Analytics
import ReactGA from "react-ga4";

// EmailJS
import emailjs from 'emailjs-com';
const ServiceID = 'service_7mqhuym';
const UserID = 'user_xIzYUPgfmPh44i1anKJf1';
const TemplateID = 'template_dtnclqa';


emailjs.init(UserID);

function ContactForm() {
 
    const [validated, setValidated] = useState(false);
    
    const handleValidation = (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        }
    
        setValidated(true);
        
        if(form.checkValidity() === true ) {
            const ValidationBox = document.getElementById('validation')
            e.preventDefault();
            emailjs.sendForm(ServiceID, TemplateID, e.target, UserID)
              .then((result) => {
                  
                // Send a custom event
                ReactGA.event({
                  category: "Forms",
                  action: "Contact Form Submit",
                  label: "Contact Form", // optional
                });


                  console.log(result.text);
                  ValidationBox.innerHTML = `
                    <div class="success">
                        Thank you for getting in touch! We will be in touch with you shortly.
                    </div>
                  `
                  
              }, (error) => {
                  console.log(error.text);
                  
                  ValidationBox.innerHTML = `
                    <div class="error">
                        There as been an error. Please try again later or contact us at <a href="mailto:info@indemandtech.com">info@indemandtech.com</a>
                    </div>
                  `
              });
        }
    };
    
    
    // function sendEmail(e) {
    //     e.preventDefault();
    //     emailjs.sendForm(ServiceID, TemplateID, e.target, UserID)
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    //  }
    
    return(
        <div>
            <div id="validation">
            </div>
            <Form noValidate validated={validated} id="form" className="contact-form" onSubmit={handleValidation}>
                <Row>
                    <Col>
                        <Form.Group controlId="user_firstname">
                            <Form.Label>First name</Form.Label>
                            <Form.Control 
                                required
                                size="md" 
                                name="user_firstname" 
                                type="text" 
                                placeholder="First name (required)"/>
                                
                            
                            <Form.Control.Feedback type="invalid">Please enter your first name</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    
                    <Col>
                        <Form.Group controlId="user_lastname">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control  
                                required
                                size="md" 
                                name="user_lastname" 
                                type="text" 
                                placeholder="Last name (required)" />
                                
                            
                            <Form.Control.Feedback type="invalid">Please enter your last name</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <Form.Group controlId="user_email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required
                                size="md" 
                                name="user_email" 
                                type="email" 
                                placeholder="Email (required)"/>
    
                            
                            <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    
                    <Col>
                        <Form.Group controlId="user_phone">
                            <Form.Label>Phone</Form.Label>
                           
                            <PhoneInput 
                              inputClass="form-control-md"
                              country={'us'}
                              inputProps={{
                                name: 'user_phone',
                                required: true,
                                autoFocus: true,
                              }}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <Form.Group controlId="message">
                            <Form.Label>Message</Form.Label>
                            <Form.Control  
                                required
                                size="md"
                                name="message" 
                                as="textarea" 
                                placeholder="Tell us how we can help" />
    
                            <Form.Control.Feedback type="invalid">Please enter your message</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                
                <input type="submit" id="button" className="btn btn--primary btn--ghost btn--submit" value="Submit" />
                
            </Form>
        
        </div>
    );

}
export default ContactForm;
import React from 'react';
import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Card } from 'react-bootstrap';
import Section from '../components/Section';
// API

import JobList from '../components/JobList';

// FontAwesome

function Careers() {
    return (
        <div className="Careers">
            <HelmetComponent PageID="Careers" />
            <Section>
                <Container>
                    <Row>
                        <JobList />
                    </Row>
                </Container>
            </Section>
          </div>  
    )
}  

export default Careers
import React from 'react';

// import 'bootstrap/scss/_variables.scss';
// import 'bootstrap/scss/mixins/_caret.scss';
// import 'bootstrap/scss/_dropdown.scss';
import {Container, Row, Col} from 'react-bootstrap';
import {Routes} from '../Routes';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import logo from '../img/accucode-logo.svg';
import './Navigation.scss';

function Navigation() {
  return (
    <Navbar collapseOnSelect expand="md" className="navigation">
      <Container>
          <Navbar.Brand href="/" title="Home | Accucode">
            <img src={logo}  alt="Accucode" />
          </Navbar.Brand>
        
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        
            
            {Routes.map((item, index) => {
                  // return item.Parent === '' && item.id != 'Home' && item.id !=':slug' && (
                  //   <Nav.Link href={item.Path} key={"page-"+index}>{item.Title}</Nav.Link>
                  // )
              
            })}
            <Nav className="navigation__menu">
            
             
              <NavDropdown title="Products" id="collapsible-nav-dropdown" className="productsNav">
                <Col sm={12}>
                  <Container className="dropdown-menu__content">
                    <Row>
                      <Col className="dropdown-menu__desc" xs={12} lg={4} >
                        <p className="fs-4">Future proof your business</p>
                        <p>Proven technology integration & reliable solutions.</p>
                      </Col>
                      
                      <Col xs={12} lg={8}>
                      {Routes.map((item, index) => {
                        return item.Parent === 'Products' && (
                          <NavDropdown.Item key={"Products-" + index}  title={item.Title} href={item.Path}>
                            <p className="blue-text bold fs-6">{item.Title}</p>
                            <p>{item.SeoTitle}</p>
                          </NavDropdown.Item>
                        )
                      })}
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <div className="dropdown-menu__background"></div>
              </NavDropdown>
              
              <NavDropdown title="Services" id="collapsible-nav-dropdown" className="servicesNav">
                <Container className="dropdown-menu__content">
                  <Row>
  
                    <Col className="dropdown-menu__desc" lg={4} >
                      <p className="fs-4">Fully Managed Services</p>
                      <p>We handle your hardware from cradle to grave.</p>
                    </Col>
                    <Col lg={8}>
                      {Routes.map((item, index) => {
                        return item.Parent === 'Services' && (
                          <NavDropdown.Item key={"services-" + index}  title={item.Title} href={item.Path}>
                            <p className="blue-text bold fs-6">{item.Title}</p>
                            <p>{item.SeoTitle}</p>
                          </NavDropdown.Item>
                        )
                      })}
                    </Col>
                  </Row>
                </Container>
                <div className="dropdown-menu__background"></div>
              </NavDropdown>
              
              <NavDropdown title="About" id="collapsible-nav-dropdown"  className="aboutNav">
                <Container className="dropdown-menu__content">
                  <Row>
                    
  
                    <Col className="dropdown-menu__desc" lg={4} >
                      <p className="fs-4">Who we are</p>
                      <p>Award-winning business technology solutions.</p>
                    </Col>
                    
                    <Col lg={8}>
                      {Routes.map((item, index) => {
                        return item.Parent === 'Company' &&  item.id != 'Careers' && (
                          <NavDropdown.Item key={"company-" + index}  title={item.Title} href={item.Path}>
                            <p className="blue-text bold fs-6">{item.Title}</p>
                            <p>{item.SeoDescription}</p>
                          </NavDropdown.Item>
                        )
                      })}
                    </Col>
                  </Row>
                </Container>
                <div className="dropdown-menu__background"></div>
              </NavDropdown>
              
              <Button className="navigation__button justify-content-end" variant="primary" href="/contact">Start your project</Button>
            </Nav>

            </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;


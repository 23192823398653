import React from 'react';
import { useParams} from "react-router-dom";
// API

import useFetch from '../utils/useFetch'
import {Posts} from '../utils/WordpressClient';

import {Container, Row, Col} from 'react-bootstrap';
import Section from '../components/Section.js'

import DOMPurify from 'dompurify';

// CSS
import Single from './SinglePost.scss'
        
function SinglePost() {
    let { slug } = useParams();
    return (
        <>  
            <CurrentPost />
        </>
    )
    
}

function CurrentPost(id) {
    
  let { slug } = useParams();
  const CurrentPost = useFetch(Posts+`&slug=${slug}`);
  
  return (
    <>
        {CurrentPost && CurrentPost.map((post, index) => (
            <div className={"single post " + {slug} }>
                <Section>
                    <Container>
                        <Row className="post__intro justify-content-center">
                            <Col  sm={10} md={10} lg={8} >
                                <h1 className="post__title">{post.title.rendered}</h1>
                                <p className="post__excerpt" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.excerpt.rendered) }}></p>
                                <figure className="post__image">
                                    <img src={post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url} />
                                </figure>
                            </Col>
                        </Row>
                        
                        <Row className="post__content justify-content-center">
                            <Col sm={10} md={10} lg={8}>
                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content.rendered) }}></div>
                    
                            </Col>
                         </Row>
 
                    </Container>
                </Section>
            </div>
        
        ))}
    </>
  );
}

export default SinglePost
import React  from 'react';
import HelmetComponent from "../components/HelmetComponent";
import { Link } from 'react-router-dom';
import {Container, Col, Row, Card } from 'react-bootstrap';
import Section from '../components/Section';

import DOMPurify from 'dompurify';
// API

import useFetch from '../utils/useFetch'
import { Posts} from '../utils/WordpressClient';

// CSS
import Styles from './News.scss';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function News() {
    return (
        <div className="News">
            <HelmetComponent PageID="News" />
            <Section className="page-hero">
                <Container className="latest">
                    <Row className="align-items-center">
                    
                        <Col xs={12}>
                            <h1>Newsroom</h1>
                        </Col>
                        <LatestPost />
                    </Row>
                </Container>
            </Section>
            
            <Section>
                <Container>
                    <Row>
                        <RecentPosts />
                    </Row>
                </Container>
            </Section>
          </div>  
    )
}  


function LatestPost(){
     const LatestPost = useFetch(Posts+'&per_page=1');
     return (
         <>
         {LatestPost && LatestPost.map((post, index) => (
             <>
                <Col xs={12} md={8}>
                    <img src={post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url} />
                </Col>      
                
                <Col xl={4}>
                    <h2 className="h3 thin">
                        {post.title.rendered}
                    </h2>
                    <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.excerpt.rendered)}}></p>
                    <Link to={`/news/${post.slug}`}>Read more </Link>
                </Col>
                </>
            ))}
         </>
        
    
    )
}


function RecentPosts() {
  const posts = useFetch(Posts+'&offset=1');
  return (
     <>
     {posts && posts.map((post, index) => (
       <Col xs={12} sm={12} md={6} lg={4} className="post-list__post post">
            <Card>
                <Link to={`/news/${post.slug}`}>
                    <Card.Img variant="top" src={post._embedded['wp:featuredmedia'][0].media_details.sizes.medium_large.source_url} /> 
                </Link>
                <Card.Body>
                    <Link to={`/news/${post.slug}`}>
                        <Card.Title >{post.title.rendered}</Card.Title>
                    </Link>
                    <p className="mb-2 mt-2 text-muted" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.excerpt.rendered) }}></p> 
                    <div className="card__actions">
                        <Card.Link href={`/news/${post.slug}`} title={post.title.rendered}> Read More <FontAwesomeIcon icon={faArrowRight} /></Card.Link>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        
      ))}
      </>
  );
}

export default News
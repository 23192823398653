import React from 'react';
import QuoteCSS from './Quote.scss';

import Button from 'react-bootstrap/Button';

const Quote = ({ children }) => {
    let subComponentList = Object.keys(Quote);

    let subComponents = subComponentList.map((key) => {
        return React.Children.map(children, (child) =>
            child.type.name === key ? child : null
        );
    });

    return (
        <>
            <div className={'quote'}>
                {subComponents.map((component) => component)}
            </div>
        </>
    );
};

const Img = (props) => 
    <div className='quote__img'> 
        <img src={props.src} /> 
    </div>;
Quote.Img = Img;

const Body = (props) => 
    <div className='quote__body'>
        {props.children}
    </div>;
Quote.Body = Body;

const Attr = (props) => 
    <div className='quote__attr'>
        {props.name.length > 0 &&
            <span className="quote__author"> 
                {props.name}
            </span>
        }
        
        <div className="quote__author-info">
            {props.title.length > 0 &&
                <span className="quote__author-title">
                    {props.title}
                    {props.company.length > 0 &&
                        ', '
                    }
                </span>
            }
            
            {props.company.length > 0 &&
                <span className="quote__author-company">
                    {props.company}
                </span>
            }
        </div>
    </div>;
Quote.Attr = Attr;

const Btn = (props) =>
    <div className="quote__btn">
        {props.href.length > 0 &&
            <Button variant={props.variant} href={props.href}>
                {props.children}    
            </Button>
        }
    </div>
Quote.Btn = Btn

export default Quote;
import {Container, Row, Col } from 'react-bootstrap';
// Components

import Section from '../components/Section';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


// Images


// Video
import HeroBackground from "../vid/it-in-datacenter.mp4"

// CSS
import Styles from './LifecycleServices.scss';

// Video
import HDInstall from '../vid/hard-drive-install.mp4'
function LifecycleServicesEOL() {
  return (
    <>
      <Section className="end-of-life">
        <Container className="service__content">
          <Row>
            <Col xs={{span:12, order:2}} lg={5}>
              <h3>Our asset reclamation methodology ensures that devices and data are <span className="blue-text">safely and securely</span> decommissioned for you and your customers</h3>
              <h4>Upgrade and end-of-life services</h4>
              <p>Whether there is an upgraded device population that requires businesses to get rid of the old technology, or there are broken assets that need salvaging, our services determine marketability and bring in top dollar for equipment that is no longer needed.</p>
              <h4>Data Wiping</h4>
              <p>Once hardware reaches the end of its life cycle, your customer’s data will remain safe and secure. We remove proprietary data on each device and minimize the risk of theft. We also inventory the data and securely store it for customer use.</p>
              
              <h4>Disposal & Recycling</h4>
              <p>While there are countless ways one can dispose of electronics, we make sure each device is recycled in an environmental friendly manner. We also take the burden of navigating federal standards and hefty fines off of your plate since our process is EPA compliant.</p>
              
            </Col>
            <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 7}}>
              <video className="service__video video--sidebar"  loop autoPlay muted >
                <source src={HDInstall} type="video/mp4" />
              </video>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default LifecycleServicesEOL;

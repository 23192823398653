import React from "react";
import {  Link } from "react-router-dom";
import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// Components
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Icon from '../components/Icon';

// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

import Separator from "../components/Separator"
//video

import PrintingVideo from '../vid/00411.mp4';
import ThreeDeeLogo from '../img/3dps-logo-horz.svg';

// Partners
import FormLabs from '../img/partners/3dps-partners/formlabs.webp'
import FusedForm from '../img/partners/3dps-partners/fusedform.webp'
import Markforged from '../img/partners/3dps-partners/markforged.webp'
import Photocentric from '../img/partners/3dps-partners/photocentric.webp'
import PushPlastic from '../img/partners/3dps-partners/pushplastic.webp'
import Shining3D from '../img/partners/3dps-partners/shining3d.webp'

// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

// CSS
import Styles from './3dPrinting.scss';
import PageHero from '../components/PageHero.scss';


function ThreeD() {
  return (
    <div className="3dPrinting">
      <HelmetComponent PageID="3dPrinting" />
      <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xl={7}>
                  <Col className="page-hero__content " xl={10} >
                    <h1 className=" text-white">Additive manufacturing the highest quality parts for your project</h1>

                
                    <Col lg={12} xl={10}>
                      <h3 className="thin text-white">Feel confident knowing that you will receive only the highest quality parts that not only meet all of your project’s requirements but exceed expectations.</h3>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={PrintingVideo} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
              <Col xl={5}>
                <Row>
                  <Row className="page-hero__logo" >
                    <img src={ThreeDeeLogo} />
                  </Row>
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Our Customers</h6>
                    <Separator />
                    <p>With the <Link to="/workforce-management-suite">4Work application</Link>, every step of our process and customer interaction is visible in real time. Customer information, print times, materials, and follow-ups are all stored in one place. </p>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={6} xl={6}>
                    <h6>Quality Control</h6>
                    <Separator />
                    <p>Printing across various printer platforms is monitored through 4Work and documented to capture data of successful prints, optimize printer and material usage, and build a schedule for production printing. Unsuccessful prints are monitored to capture material usage and time losses.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Troubleshooting & Upgrades</h6>
                    <Separator />
                    <p>Documentation includes a visual library with a complete set of instructions for any technician to easily install, troubleshoot, or upgrade a printer even as new technologies or techniques are introduced.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Service & Repairs</h6>
                    <Separator />
                    <p>We are able to scale service and repairs from the to the receipt of the printer, to the evaluation, and repair. Get reports for any repair including any pictures that were taken during the repair process.</p>
                  </Col>
                </Row>
       
              </Col>
            </Row>
          </Container>
        </Section>
         <Section className="partners light-grey-bg">
          <Container>
            <Row className="align-items-center">
               <Col sm={12} md={12} lg={4}>
                  <h3>3D printing solutions from <span className="blue-text">cutting edge</span> manufacturers
                  </h3>
                </Col>
              <Col sm={12} md={{span:12 }}lg={{span: 8, offset: 0}}>
                  <Swiper
                    className="logo-slider"
                                        breakpoints={{
                      // when window width is >= 0px
                      0: {
                        width: 320,
                        slidesPerView: 1,
                      },// when window width is >= 320px
                      320: {
                        width: 320,
                        slidesPerView: 2,
                      },// when window width is >= 720px
                      720: {
                        width: 720,
                        slidesPerView: 3,
                      },
                      
                    }}
                    modules={[Autoplay, Navigation]}
                    spaceBetween={50}
                    slidesPerView={3}
                    autoplay
                    // navigation
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide><img src={FormLabs}/></SwiperSlide>
                    <SwiperSlide><img src={FusedForm}/></SwiperSlide>
                    <SwiperSlide><img src={Markforged} /></SwiperSlide>
                    <SwiperSlide><img src={Photocentric}/></SwiperSlide>
                    <SwiperSlide><img src={Shining3D} /></SwiperSlide>
                  </Swiper>
                  {
                    //
                    //<div className="swiper__nav">
                    //  <div className="swiper-button-prev"></div>
                    //  <div className="swiper-button-next"></div>
                    //</div>
                    //
                  }
              </Col>
            </Row>
          </Container>
        </Section>
        
    </div>
  );
}

export default ThreeD

import React, {useEffect} from 'react';
import HelmetComponent from "../components/HelmetComponent";
// Components

import { Link } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Section from '../components/Section'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


// Images

import ForWork from '../img/4work-logo-white.svg'

// Videos

import HeroBackground from '../vid/drone.mp4'



// Tab content
import Drones from './DigitalManufacturing--Drones'
import ProcessAutomation from './DigitalManufacturing--ProcessAutomation'
import Services from './DigitalManufacturing--Services'

import Styles from './DigitalManufacturing.scss';

// Images
//===========================

function DigitalManufacturing() {

  // window.prerenderReady = false;
  // console.log('prerenderReady = ' + window.prerenderReady)
  
  // useEffect(() => {
    
  //   window.prerenderReady = true;
  //   console.log('useEffect prerenderReady == ' + window.prerenderReady)
  // },[])
  
  return (
    <div className="OperationsLogistics">
        <HelmetComponent PageID="OperationsLogistics" />
        <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xs={12}>
                  <Col className="page-hero__content " lg={7} >
                    <h1 className=" text-white">
                     Technology for operational logistics drive <span className="bold">consistent outcomes</span> in an unpredictable market
                    </h1>

                
                    <Col lg={12} xl={10}>
                      <Row>
                        <h3 className="thin text-white">Scalability and connectivity between devices give you visibility, accurate data collection, and easy reporting for your business in any environment</h3>
                        </Row>
                        
                        <a href="https://www.youtube.com/watch?v=YUZYLh1BxaY" target="_blank"><Button variant="secondary">Watch The Dawn of Drones: Episode 50 with Accucode CEO Kevin Price</Button></a>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={HeroBackground} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
            
            </Row>
          </Container>
        </Section>
        
        <Section className="end light-grey-bg">
          <Tabs>
            <Container>
              <TabList>
                <Tab>Drones & Robotics</Tab>
                <Tab>Assets & Operations</Tab>
                <Tab>Fulfillment & Repair</Tab>
              </TabList>
            </Container>
            
            <TabPanel>
              <Drones />
            </TabPanel>
            <TabPanel>
              <ProcessAutomation />
            </TabPanel>
            <TabPanel>
              <Services />
            </TabPanel>
          </Tabs>
        </Section>
        
    </div>
  );
}

export default DigitalManufacturing;

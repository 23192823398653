import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link, Switch, Route } from 'react-router-dom';
// Components

import PageHero from '../components/PageHero.scss';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';
import Separator from "../components/Separator"



// Images

import InDemand from '../img/indemand-pba.svg'

// Partners
import Axis from '../img/partners/accucode-partners/axis-communications.webp'
import Cisco from '../img/partners/accucode-partners/cisco-logo.webp'
import Extreme from '../img/partners/accucode-partners/extreme-networks.webp'
import Honeywell from '../img/partners/accucode-partners/honeywell-logo.webp'
import Nokia from '../img/partners/accucode-partners/nokia.webp'
import Panasonic from '../img/partners/accucode-partners/panasonic-logo.webp'
import Verkada from '../img/partners/accucode-partners/verkada-logo.webp'
import Zebra from '../img/partners/accucode-partners/zebra-logo.webp'

// Video
import HeroBackground from "../vid/it-in-datacenter.mp4"

import '../components/LogoSlider.scss';
// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module


//Tab pages
import LifecycleServicesDeployments from './LifecycleServices--Deployments';
import LifecycleServicesProcurement from './LifecycleServices--Procurement';
import LifecycleServicesInstallations from './LifecycleServices--Installations';
import LifecycleServicesRepairs from './LifecycleServices--Repairs';
import LifecycleServicesEOL from './LifecycleServices--EOL';

// CSS
import Styles from './LifecycleServices.scss';


function LifecycleServices() {
  return (
    <div className="LifecycleServices">
      <HelmetComponent PageID="LifecycleServices" />
      <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xl={7}>
                  <Col className="page-hero__content " xl={10} >
                    <h1 className=" text-white">
                      Customized and <span className="bold">white labeled</span> technology services
                    </h1>

                
                    <Col lg={12} xl={10}>
                      <h3 className="thin text-white">We work behind the scenes at every stage of technology rollouts for your customers as telecom providers, software ISVs, hardware OEMs, and wholesale distributors.</h3>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={HeroBackground} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
              <Col xl={5}>
                <Row>
                  <img className="indemand-logo" src={InDemand} />
                  <Col className="gy-5" sm={6} md={12} xl={10}>
                    <h4>Complete asset management and tracking</h4>
                    <Separator />
                    <p>
InDemand offers a white label solution to take the headache of network deployment with expert, customized deployment options so that you have the power to choose which solutions and support services that are both right for your business and support your customers needs. </p>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={12} xl={10}>
                    <h4>Two operations depots located in Louisville, KY and Englewood, CO</h4>
                    <Separator />
                    <p>InDemand Technologies has the tools, people, and processes for B2B technology rollouts in many industries– from telecom to restaurant POS systems from retail MDM and from scaling your customers’ operations, to new store openings.</p>
                  </Col>
                </Row>
       
              </Col>
            </Row>
          </Container>
        </Section>
        
          <Section className="stats light-grey-bg">
          <Container>
            <Row className="align-items-center">
               <Col sm={12} md={12} lg={12}xl={4}>
                  <h3>
                    Enhance <span className="blue-text">process automation</span> in growing markets with incredible results
                  </h3>
                  
                </Col>
              <Col sm={12} md={{span:12 }} lg={12} xl={{span: 7, offset: 1}} >
                <Row className="align-items-center">
                  <Col xs={12} lg={4} className="stat">
                    <CountUp
                      delay={0}
                      start={0}
                      end={5000}
                      duration={2.75}
                      separator=" "
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix="+"
                      onEnd={() => console.log('Ended! 👏')}
                      onStart={() => console.log('Started! 💨')}
                    >
                      {({ countUpRef, start }) => (
                        <div>
                          <span  className="stat__number h2 blue-text" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                    <h4 className="stat__context">Service tickets processed per year</h4>
                  </Col>
                  
                  <Col xs={12} lg={4} className="stat">
                    <CountUp
                      delay={0}
                      start={0}
                      end={120000}
                      duration={2.75}
                      separator=" "
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix="+"
                      onEnd={() => console.log('Ended! 👏')}
                      onStart={() => console.log('Started! 💨')}
                    >
                      {({ countUpRef, start }) => (
                        <div>
                          <span  className="stat__number h2 blue-text" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                    <h4 className="stat__context">Devices processed per year</h4>
                  </Col>
                  
                  <Col xs={12} lg={4} className="stat">
                    <CountUp
                      delay={0}
                      start={0}
                      end={150000}
                      duration={2.75}
                      separator=" "
                      decimals={0}
                      decimal=","
                      prefix=""
                      suffix="+"
                      onEnd={() => console.log('Ended! 👏')}
                      onStart={() => console.log('Started! 💨')}
                    >
                      {({ countUpRef, start }) => (
                        <div>
                          <span className="stat__number h2 blue-text" ref={countUpRef} />
                        </div>
                      )}
                    </CountUp>
                    <h4 className="stat__context">Devices processed per year</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
        
        <Section className="end tabs-section ">
          <Tabs>
            <Container>
              <TabList>
                <Tab>
                  <Link to="/lifecycle-services/deployments">
                    Deployments
                  </Link>
                </Tab>
                <Tab>
                  <Link to="/lifecycle-services/installations">
                    Installations
                  </Link>
                </Tab>
                <Tab>
                  <Link to="/lifecycle-services/procurement">
                    Hardware Procurement
                  </Link>
                </Tab>
                <Tab>
                  <Link to="/lifecycle-services/service-support">
                    Service & Support
                  </Link>
                </Tab>
                
                <Tab>
                  <Link to="/lifecycle-services/asset-reclamation">
                    Asset Reclamation
                  </Link>
                </Tab>
              </TabList>
            </Container>
            
            
           
          </Tabs>
        </Section>
    </div>
  );
}

export default LifecycleServices;

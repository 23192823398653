import HelmetComponent from "../components/HelmetComponent";
// Components

import Section from '../components/Section';
import {Container, Row, Col } from 'react-bootstrap';
import Separator from "../components/Separator"

// css
import Styles from './ManagedNetworks.scss';


// Video

import HeroBackground from '../vid/network.mp4'


// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import '../components/LogoSlider.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
// Images

// Partners
import Cisco from '../img/partners/accucode-partners/cisco-logo.webp'
import Extreme from '../img/partners/accucode-partners/extreme-networks.webp'
import Nokia from '../img/partners/accucode-partners/nokia.webp'


// Video
import Engineer from '../vid/engineer-in-datacenter.mp4'

function ManagedNetworks() {
  return (
    <div className="ManagedNetworks">
      <HelmetComponent PageID="ManagedNetworks" />
       <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xl={7}>
                  <Col className="page-hero__content " xl={10} >
                    <h1 className=" text-white">Fully managed and <span className="bold">self provisioned</span> cloud infrastructure</h1>
                    <Col lg={12} xl={10}>
                      <h3 className="thin text-white">Give your employees work from anywhere capabilities or easily get a new branch office location up and running through remote configuration.</h3>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={HeroBackground} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
              <Col xl={5}>
                <Row>
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Secure teleworker capabilities</h6>
                    <Separator />
                    <p>Highly secure tunnels support home-based workers as well as secure site to site connectivity. </p>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={6} xl={6}>
                    <h6>Integrated security</h6>
                    <Separator />
                    <p>Ensure secure remote access with firewalls and private pre-shared keys for WiFi.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Rapid deployments</h6>
                    <Separator />
                    <p>New locations can be up and running in minutes with zero touch provisioning.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Flexible WAN</h6>
                    <Separator />
                    <p>Gain the ability to connect remote sites with whatever WAN connectivity is readily available.</p>
                  </Col>
                </Row>
       
              </Col>
            </Row>
          </Container>
        </Section>
        
        <Section className="partners light-grey-bg">
          <Container>
            <Row className="align-items-center">
               <Col sm={12} md={12} lg={4}>
                  <h3>
                    <span className="blue-text">Secure</span> and <span className="blue-text">reliable</span> networks from the top brands in the industry
                  </h3>
                </Col>
              <Col sm={12} md={{span:12 }}lg={{span: 8, offset: 0}}>
                  <Swiper
                    className="logo-slider"
                                        breakpoints={{
                      // when window width is >= 0px
                      0: {
                        width: 320,
                        slidesPerView: 1,
                      },// when window width is >= 320px
                      320: {
                        width: 320,
                        slidesPerView: 2,
                      },// when window width is >= 720px
                      720: {
                        width: 720,
                        slidesPerView: 3,
                      },
                      
                    }}
                    modules={[Autoplay, Navigation]}
                    spaceBetween={50}
                    slidesPerView={3}
                    autoplay
                    // navigation
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide><img src={Cisco}/></SwiperSlide>
                    <SwiperSlide><img src={Nokia}/></SwiperSlide>
                    <SwiperSlide><img src={Extreme} /></SwiperSlide>
                  </Swiper>
                  {
                    //
                    //<div className="swiper__nav">
                    //  <div className="swiper-button-prev"></div>
                    //  <div className="swiper-button-next"></div>
                    //</div>
                    //
                  }
              </Col>
            </Row>
          </Container>
        </Section>
        
         <Section className="marketplace">
         
                <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={5}>
                    <h3>Your business deserves the same services and <span className="blue-text">solutions that power</span> entire football stadiums</h3>
                    <h4>Centralized orchestration and management</h4>
                    <p>Remote locations without IT resources managed and provisioned through a single pane of glass.</p>
                    
                    <h4>Prevention of remote office sprawl</h4>
                    <p>Integrated LAN/ PoE, Wi-Fi, WAN and security capabilities in a cost effective, space-efficient bundle.</p>
                    
                    <h4>Simple traffic prioritization</h4>
                    <p>Prioritize traffic from groups of users or applications.</p>


                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 7}}>
                    <video className="cctv__video video--sidebar"  loop autoPlay muted >
                      <source src={Engineer} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
         
        </Section>
               
      
      

    </div>
  );
}

export default ManagedNetworks;

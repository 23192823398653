// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import '../components/LogoSlider.scss';
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

// Partners
import Axis from '../img/partners/accucode-partners/axis-communications.png'
import Cisco from '../img/partners/accucode-partners/cisco-logo.png'
import Extreme from '../img/partners/accucode-partners/extreme-networks.png'
import Honeywell from '../img/partners/accucode-partners/honeywell-logo.png'
import Nokia from '../img/partners/accucode-partners/nokia.png'
import Panasonic from '../img/partners/accucode-partners/panasonic-logo.png'
import Verkada from '../img/partners/accucode-partners/verkada-logo.png'
import Zebra from '../img/partners/accucode-partners/zebra-logo.png'


function Partners() {

  return (
      <>
      <Swiper
        className="logo-slider"
        modules={[Autoplay, Navigation]}
        spaceBetween={50}
        slidesPerView={3}
        autoplay
        // navigation
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        >
        <SwiperSlide><img src={Honeywell} /></SwiperSlide>
        <SwiperSlide><img src={Panasonic} /></SwiperSlide>
        <SwiperSlide><img src={Cisco}/></SwiperSlide>
        <SwiperSlide><img src={Nokia}/></SwiperSlide>
        <SwiperSlide><img src={Zebra}/></SwiperSlide>
        <SwiperSlide><img src={Verkada} /></SwiperSlide>
        <SwiperSlide><img src={Extreme} /></SwiperSlide>
        <SwiperSlide><img src={Axis} /></SwiperSlide>
    </Swiper>
    </>
  );
}

export default Partners;
import HelmetComponent from "../components/HelmetComponent";
import Section from '../components/Section';
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

//Lottie 
import Lottie from "lottie-react";
import Email from '../img/lottie/email.json';


// Styles
import Styles from './ThankYou.scss';

function ThankYou() {
  return (
    <div className="ThankYou">
      <Section>
        <Container>
          <Row>
            <Col className="ThankYou__img" xs={12} sm={12} md={{span: 6, offset: 3}}>
              <Lottie animationData={Email} />
            </Col>
          </Row>
          
          <Row>
            <Col className="text-center">
              <h1>Thank you!</h1>
              <p>We've gotten your message and will be in touch soon.</p>
              <Link to="/"><Button variant="primary" className="thank-you__home">Back home</Button></Link>
            </Col>
          </Row>
        </Container>
      </Section>
    </div>
  );
}


export default ThankYou;

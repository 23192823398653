import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link, Switch, Route } from 'react-router-dom';

import {useBreakpoint} from '../utils/Breakpoint'
// Components


import PageHero from '../components/PageHero.scss';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';
import Separator from '../components/Separator';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


// Images
// Partners
import Axis from '../img/partners/accucode-partners/axis-communications.webp'
import Cisco from '../img/partners/accucode-partners/cisco-logo.webp'
import Extreme from '../img/partners/accucode-partners/extreme-networks.webp'
import Honeywell from '../img/partners/accucode-partners/honeywell-logo.webp'
import Nokia from '../img/partners/accucode-partners/nokia.webp'
import Panasonic from '../img/partners/accucode-partners/panasonic-logo.webp'
import Verkada from '../img/partners/accucode-partners/verkada-logo.webp'
import Zebra from '../img/partners/accucode-partners/zebra-logo.webp'
import Epson from '../img/partners/accucode-partners/epson-logo.webp'
import Aruba from '../img/partners/accucode-partners/aruba-logo.webp'

// Partners
import Inc5000 from '../img/awards/inc5000.webp'
import CRNSecurity from '../img/awards/crnsecurity.webp'
import Solution500 from '../img/awards/solution500.webp'
// Video
import HeroBackground from "../vid/receptionist-tablet.mp4"

import '../components/LogoSlider.scss';
// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module

//Tab pages
import HardwareComputing from './Hardware--Computing';
import HardwareNetworking from './Hardware--Networking';
import HardwarePrinting from './Hardware--Printing';
import Hardware3D from './Hardware--3D';


// CSS
import Styles from './Hardware.scss';
function Hardware() {
  return (
    <div className="CoreHardware">
      <HelmetComponent PageID="CoreHardware" />
      <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xl={7}>
                  <Col className="page-hero__content " xl={10} >
                    <h1 className=" text-white">
                      Technology that <span className="bold">powers stadiums</span> and the largest retailers you go to every day
                    </h1>

                
                    <Col lg={12} xl={10}>
                      <h3 className="thin text-white">Integrate without hassle while our award winning Lifecycle Services take stress off of your IT department.</h3>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={HeroBackground} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
              <Col xl={5}>
                <Row>
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Guidance & consulting</h6>
                    <Separator />
                    <p>Working with a consultant that is OEM agnostic gives you more variety to address specific pain points and come up with a plan.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={6} xl={6}>
                    <h6>End-to-end services</h6>
                    <Separator />
                    <p>It doesn’t end with acquisitions. Get after sales services such as maintenance, repairs, help desk, and remote administration. </p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Complete solutions</h6>
                    <Separator />
                    <p>We’ll never leave you hanging. With core technologies, you get access to services beyond the initial implementation to focus on business goals while we work in the background to configure and match any unique business processes.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Visibility for a clear path forward</h6>
                    <Separator />
                    <p>Having a thoughtful and organized plan means investment in tech deployments that are future forward– success not just now, but in the future.</p>
                  </Col>
                </Row>
       
              </Col>
            </Row>
          </Container>
        </Section>
        
        <Section>
          <Container>
              <Row>
                <Col sm={12} md={12} lg={4}>
                   <h3>
                      <span className="blue-text">The best</span> AIDC, mobile, wireless, and POS technologies</h3>
                   <p>We’ve partnered with established and reliable brands known for innovation. This variety enables us to find solutions together that work with your supply chain timelines and quantities.</p>
                </Col>
                <Col sm={12} md={{span:12 }}lg={{span: 8, offset: 0}}>
                    <Swiper
                      className="logo-slider"                   
                      breakpoints={{
                        // when window width is >= 0px
                        0: {
                          width: 320,
                          slidesPerView: 1,
                        },// when window width is >= 320px
                        320: {
                          width: 320,
                          slidesPerView: 2,
                        },// when window width is >= 720px
                        720: {
                          width: 720,
                          slidesPerView: 3,
                        },
                        
                      }}
                      modules={[Autoplay, Navigation]}
                      spaceBetween={50}
                      slidesPerView={3}
                      autoplay
                      // navigation
                      onSlideChange={() => console.log('slide change')}
                      onSwiper={(swiper) => console.log(swiper)}
                    >
                      
                      <SwiperSlide><img src={Panasonic} /></SwiperSlide>
                      <SwiperSlide><img src={Cisco}/></SwiperSlide>
                      <SwiperSlide><img src={Nokia}/></SwiperSlide>
                      <SwiperSlide><img src={Zebra}/></SwiperSlide>
                      <SwiperSlide><img src={Verkada} /></SwiperSlide>
                      <SwiperSlide><img src={Extreme} /></SwiperSlide>
                      <SwiperSlide><img src={Honeywell} /></SwiperSlide>
                      <SwiperSlide><img src={Axis} /></SwiperSlide>
                      <SwiperSlide><img src={Epson} /></SwiperSlide>
                      <SwiperSlide><img src={Aruba} /></SwiperSlide>
                    </Swiper>
                    {
                      //
                      //<div className="swiper__nav">
                      //  <div className="swiper-button-prev"></div>
                      //  <div className="swiper-button-next"></div>
                      //</div>
                      //
                    }
                </Col>
            </Row>
          </Container>
        </Section>
        
        <Section className="hardware__tabs">
          <Tabs>
            <Container>
              <TabList>
                <Tab>
                  <Link to='/hardware/computing-data-capture'>
                    Computing & Data Collection
                  </Link>
                </Tab>
                
                <Tab>
                  
                  <Link to='/hardware/networking'>
                    Networking
                  </Link>
                </Tab>
                <Tab>
                  <Link to='/hardware/printing'>
                    Printing & Labels
                  </Link>
                </Tab>
                <Tab>
                  <Link to='/hardware/3d-printing'>
                    3D Printing Materials & Processes
                  </Link>
                </Tab>
              </TabList>
            </Container>
            
          </Tabs>
        </Section>
        
        
        
    </div>
  );
}


export default Hardware;

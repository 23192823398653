import React from 'react';
import CSS from './Separator.scss';

function Separator(props) {
  
  return (
    <div className="separator"></div>
  );
}

export default Separator;


import {Container, Row, Col } from 'react-bootstrap';
// Components

import Section from '../components/Section';

// Images


// CSS
import Styles from './LifecycleServices.scss';

import Deployment from "../vid/conveyer-belt.mp4"

function LifecycleServicesDeployment() {
  return (
    <>
      
        
        <Section className="what-we-do">
           <Container className="deployment__content">
                  <Row>
                    <Col xs={{span:12, order:2}} lg={5}>
                      <h3>Minimize downtime with preconfigured devices & <span className="blue-text">next-day delivery</span>   </h3>
                      <p>Every business has different requirements for the devices they deploy. With InDemand’s deployment solutions, we can quickly service and deploy their devices without errors. Our custom deployment services give ISVs, OEMs, Telecom providers, and 3D print companies the ability to free up internal resources while we handle golden image development, staging & configuration, quality control, and packing & shipping.</p>
                      <Row>
                        <Col className="gy-5" sm={6} md={6}>
                          <h6>Golden Image Development</h6>
                          <p>We work with you to develop a standard image for all devices deployed. By managing backup files of your customer’s configuration settings and software applications, we’re able to service and deploy devices quickly, and without error.</p>
                        </Col>
                        
                        <Col className="gy-5" sm={6}md={6}>
                          <h6>Staging & Configuration</h6>
                          <p>Devices are always staged with the appropriate accessories and configured to the proper OS and network settings. These services can be done at both a customer and site level to ensure a working solution as soon as it hits the user’s hands.</p>
                        </Col>
                        
                        <Col className="gy-5" sm={6} md={6}>
                          <h6>Quality Control</h6>
                          <p>Before a device is shipped, our technicians perform a sequence of tests to identify any issues before it leaves our facility. We even have simulated test environments that can provide proof of concept before we deploy your solution.</p>
                        </Col>
                        
                        <Col className="gy-5" sm={6} md={6}>
                          <h6>Packaging & Shipping</h6>
                          <p>Once devices have been tested, our shipping team boxes the hardware with compatible accessories, batteries, charging cables and supplies. We even offer laser-etched engravings on certain hardware and custom-branded packaging depending on your needs.</p>
                        </Col>
                      </Row>
  
  
                    </Col>
                    <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 7}}>
                      <video className="deployment__video video--sidebar"  loop autoPlay muted >
                        <source src={Deployment} type="video/mp4" />
                      </video>
                    </Col>
                  </Row>
                </Container>
        </Section>
        
    </>
  );
}

export default LifecycleServicesDeployment;

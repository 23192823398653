import React from 'react';
import { useParams} from "react-router-dom";

import {Helmet} from "react-helmet";

// API
import useFetch from '../utils/useFetch'
import {Jobs} from '../utils/WordpressClient';

import {Container, Row, Col} from 'react-bootstrap';
import Section from '../components/Section.js'

import DOMPurify from 'dompurify';
// CSS
import SingleCareer from './SingleCareer.scss'
        
function SingleJob() {
    let { slug } = useParams();
    return (
        <>  
            <CurrentJob />
        </>
    )
    
}

function CurrentJob(id) {
    
  let { slug } = useParams();
  const CurrentJob= useFetch(Jobs+`&slug=${slug}`);
  
  return (
    <>
        {CurrentJob && CurrentJob.map((post, index) => (
            
            <div className={"single post single__job " + {slug} }>
                <Helmet>
                    <title>{post.position_title} | Accucode Careers</title>
                </Helmet>
                <Section>
                    <Container>
                        <Row>
                            <h1>{post.position_title}</h1>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="job__description">
                                    <h4>Job overview:</h4>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.position_description)}} style={{whiteSpace: 'pre-wrap'}}></div>
                                </div>
                                
                                <div className="job__responsibilities">
                                    <h4>Responsibilites:</h4>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.position_responsibilities)}} ></div>
                                </div>
                                
                                
                                <div className="job__education">
                                    <h4>Education:</h4>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.position_educationRequirements)}} ></div>
                                </div>
                                
                                <div className="job__qualifications">
                                    <h4>Qualifications:</h4>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.position_qualifications)}} ></div>
                                </div>
                                
                                <div className="job__benefits">
                                    <h4>Benefits:</h4>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.position_job_benefits)}} ></div>
                                </div>
                                
                                <div className="job__contacts">
                                    <h4>Contacts:</h4>
                                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.position_contacts)}}  style={{whiteSpace: 'pre-wrap'}}></div>
                                </div>
                            </Col>
                            
                            <Col xs={12} md={4}>
                                {
                                //<div className="job__hiring-org">
                                //    <img src={post.position_logo} />
                                //</div>
                                }
                                <div className="job__employment-type">
                                    <h4>Employment Type</h4>
                                    {post.position_employment_type === 'FULL_TIME' &&
                                        <span>Full time</span>
                                    }
                                </div>
                                <div className="job__location">
                                    <h4>Job Location</h4>
                                    <ul>
                                        <li className="job__location-address">{post.position_job_location_streetAddress}</li>
                                        <li className="job__location-city">{post.position_job_location}, {post.position_job_location_postalCode}</li>
                                    </ul>
                                </div>
                                <div className="job__hours">
                                    <h4>Working Hours</h4>
                                    {post.position_work_hours}
                                </div>
                                <div className="job__salary">
                                    <h4>Base Salary</h4>
                                    ${post.position_base_salary} - ${post.position_base_salary_upto} <span className="job__salary-unit">per {post.position_salary_unittext}</span>
                                
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            </div>
        
        ))}
    </>
  );
}

  
function JobType() {
    
    let { slug } = useParams();
    const CurrentJob= useFetch(Jobs+`&slug=${slug}`);
    let JobType = ''
    return(
        <>
        {CurrentJob && CurrentJob.map( (post, index, JobType) => (
             post.position_employment_type
        ))}
        </>
    )
    
  
}

export default SingleJob
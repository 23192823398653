import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';

import { Link } from 'react-router-dom';

// Components

import PageHero from '../components/PageHero.scss';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Icon from '../components/Icon';
import ContentBox from '../components/ContentBox';
import Separator from "../components/Separator"

// Form
import AiContactForm from '../components/forms/AiContactForm';


// Videos 
import HeroBackground from '../vid/machine-learning.mp4'
//Images

// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

import RoboticsAutomation from '../img/robotics-automation.jpg'
// Icons
import DataArch from '../img/icons/data-architecture.svg';
import KPI from '../img/icons/kpi.svg';
import PredictiveAnal from '../img/icons/analytics.svg';
import Prototyping from '../img/icons/prototyping.svg';
import Robotics from '../img/icons/robotics.svg';
import EdgeTech from '../img/icons/ai-icon.svg';
import Maintenance from '../img/icons/maintenance.svg';
import Training from '../img/icons/training.svg';

//video
import Kiosk from '../vid/00331_loop.mp4'
import Code from '../vid/computer-code.mp4'

function EdgeAI() {
  return (
    <div className="EdgeAI">
      <HelmetComponent PageID="EdgeAI" />
      
      <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xl={7}>
                  <Col className="page-hero__content " xl={10} >
                    <h1 className=" text-white">
                      Give your business the power of <span className="bold"> machine learning</span>
                    </h1>

                
                    <Col lg={12} xl={10}>
                      <h3 className="thin text-white">EdgeAI hosts and enables a wide range of AI applications software with visual cognition, a natural language chatbot, and machine learning at its core.</h3>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={HeroBackground} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
              <Col xl={5}>
                <Row>
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Entry control</h6>
                    <Separator />
                    <p> Contactless, self-service technology driven by AI with sensors for facial recognition, timekeeping, and data collection.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={6} xl={6}>
                    <h6>Payment processing</h6>
                    <Separator />
                    <p>Improve the speed and efficiency of the payment process by reducing the extent to which humans need to be involved.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Health screening</h6>
                    <Separator />
                    <p>Quickly and effectively screen employees and visitors to mitigate public health risks.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Business process automation</h6>
                    <Separator />
                    <p>AI driven automation improves performance, efficiency, and innovation.</p>
                  </Col>
                </Row>
       
              </Col>
            </Row>
          </Container>
        </Section>
        
        <Tabs>
        
            <Container>
              <TabList>
                <Tab>EdgeAI Kiosk</Tab>
                <Tab>AI Orchestration & Consulting</Tab>
              </TabList>
            </Container>
            
          <TabPanel>
              <Section>
                <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={5}>
                    <Col xs={12} lg={10} >
                      
                       <h3>A powerful and easy to use solution</h3>
                    </Col>
                    <Row>
                       <p>The intuitive dashboard connects to every kiosk in your network across as many different locations as you need. The kiosk includes its own storage, processing, and power. It can run for hours without power or connectivity.</p>
                      <p>Processes data at the point of transaction and sends up the meta-data. Or, it connects to the cloud for real-time streaming of duplexed audio, video, sensor data, and more.
       </p>
                    </Row>
                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:7, order: 2}} xl={{span: 7}}>
                    <video className="cctv__video video--sidebar"  loop autoPlay muted >
                      <source src={Kiosk} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              </Section>
          </TabPanel>
          
          <TabPanel>
              <Section>
                <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={5}>
                    <h3>Customized machine learning software and AI enabled IoT edge technology</h3>
                       
                      <h4>Chatbots & NLP technology</h4>
                      <p>Digital assistance & digital management utilizes NLP in chatbot interfaces for business process roles.</p>
                      <h4>Visual deep learning</h4>
                      <p>Photo and streaming video analysis includes deep learning networks for automated analysis and object ID tracking.</p>
                      
                      <h4>Decision/detection engines</h4>
                      <p>Data architecture is critical for machine learning. We offer data generalization and feature engineering.</p>
                      
                      <p><Link to="/contact">Contact us</Link> for customized machine learning software and AI enabled IoT edge technology that gives your workforce the power to perform at a higher level in any environment.</p>


                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 7}}>
                    <video className="cctv__video video--sidebar"  loop autoPlay muted >
                      <source src={Code} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              
               
              </Section>
          </TabPanel>
        </Tabs>
        

        
    </div>
  );
}

export default EdgeAI;


// API

import axios from 'axios';
import useFetch from '../utils/useFetch'
import apiFetch from '@wordpress/api-fetch';
import {Jobs, Posts} from '../utils/WordpressClient';

// Components
import { Link } from 'react-router-dom';
import {Container, Col, Row, Card } from 'react-bootstrap';
import Section from '../components/Section';

// CSS
import CSS from '../components/JobList.scss'

function JobList() {
  const jobs = useFetch(Jobs);
  console.log(jobs)
  if (jobs != 0) {
      return (
         <Section className="job-list">
            <Container>
                <Row>
                    <h3 className="text-center">Join the Accucode team!</h3>
                </Row>
             <>
                 {jobs && jobs.map((job, index) => (
                    <>
                    <Link to={`/careers/${job.slug}`}>
                        <Row className="job-list__job align-items-center">
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <strong className="job-list__job-title">{job.position_title}</strong>
                            </Col>
                            
                            <Col xs={6} lg={4}>
                                {job.position_job_location}
                            </Col>
                        </Row>
                    </Link>
                    </>
                  ))} 
              </>
              </Container>
          </Section>
            
      );
  } else {
    return(
        <Col xs={12} sm={12} md={8}>
            <h1>Unfortunately, we're not actively hiring at the moment.</h1>
            <p>But don't worry! We're always looking for new talent that fits our team. Send us an email at <a href="mailto:hr@accucode.com">hr@accucode.com</a> and let us know you're interested.</p>
        </Col>
    );
  }
}

export default JobList;
const Root = 'https://wp.accucode.com/';

const Endpoints =  {
    Posts:  'wp-json/wp/v2/posts?_embed',
    Careers:  'wp-json/wp/v2/jobs?_embed'
}

const Posts = Root+Endpoints.Posts;
const Jobs = Root+Endpoints.Careers;

export {Posts, Jobs}
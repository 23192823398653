
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {BreakpointProvider, useBreakpoint} from './utils/Breakpoint'

// Components
import Navigation from './components/Navigation.js';
import Footer from './components/Footer.js';

// Bootstrap
import bootstrap from 'bootstrap';

// Typography
// import typography from './typography'

// Globals
import Main from './main.scss';

// Pages
import Home from './pages/Home';
import Hardware from './pages/Hardware';

import HardwareComputing from './pages/Hardware--Computing';
import CloudSecurity from './pages/Hardware--CloudSecurity';
import HardwareNetworking from './pages/Hardware--Networking';
import HardwarePrinting from './pages/Hardware--Printing';
import Hardware3D from './pages/Hardware--3D';


import ForWork from './pages/4Work';
import WorkforceOptimization from './pages/4Work--WorkforceOptimizationSoftware';
import OrderManagement from './pages/4Work--OrderManagementSoftware';
import ManagedNetworks from './pages/ManagedNetworks';

import LifecycleServices from './pages/LifecycleServices';

import LifecycleServicesDeployments from './pages/LifecycleServices--Deployments';
import LifecycleServicesProcurement from './pages/LifecycleServices--Procurement';
import LifecycleServicesInstallations from './pages/LifecycleServices--Installations';
import LifecycleServicesRepairs from './pages/LifecycleServices--Repairs';
import LifecycleServicesEOL from './pages/LifecycleServices--EOL';

import EdgeAI from './pages/EdgeAI';

import ThreeD from './pages/3dPrinting';

import About from './pages/About';
import News from './pages/News';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import ThankYou from './pages/ThankYou';

import FourOhFour from './pages/404';

// Landing Pages
import DigitalManufacturing from './pages/DigitalManufacturing';


// Single post template
import SinglePost from './pages/SinglePost';
import SingleJob from './pages/SingleCareer';

// Terms and Conds

import Terms from './pages/Terms';

// ScrollToTop

import ScrollToTop from "./components/ScrollToTop";



function App(breakpoint) {
  // Hotjar
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:388583,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  
  
  // Google Analytics
  const TRACKING_ID = "G-WX0VGYF9BY"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  // End GA 
  window.prerenderReady = false;
  console.log('prerenderReady = ' + window.prerenderReady)
  
  useEffect(() => {
    
    window.prerenderReady = true;
    console.log('useEffect prerenderReady == ' + window.prerenderReady)
  },[])
  
  return (
    
    
    <Router>
      <ScrollToTop>
        <Navigation />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          
          {
            // Products
          }
        
        
          
          <Route path="/hardware">
            <Hardware />
            <Switch>
              <Route exact path="/hardware">
                <HardwareComputing className="computing"/>
              </Route>
              <Route exact path="/hardware/cloud-security">
                <CloudSecurity />
              </Route>
              <Route exact path="/hardware/computing-data-capture">
                <HardwareComputing />
              </Route>
              <Route path="/hardware/networking">
                <HardwareNetworking />
              </Route>
              <Route path="/hardware/printing">
                <HardwarePrinting />
              </Route>
              <Route path="/hardware/3d-printing">
                <Hardware3D />
              </Route>
            </Switch>
          </Route>
          
          <Route path="/workforce-management-suite">
            <ForWork />
          </Route>
          
          <Route path="/managed-networks" >
           <ManagedNetworks />
          </Route>
          
          {
            // Services
          }
          <Route path="/lifecycle-services" >
            <LifecycleServices />
            <Switch>
              
              <Route exact path="/lifecycle-services">
                  <LifecycleServicesDeployments />
              </Route>
              <Route path="/lifecycle-services/deployments">
                  <LifecycleServicesDeployments />
              </Route>
              <Route path="/lifecycle-services/installations">
                  <LifecycleServicesInstallations />
              </Route>
              <Route path="/lifecycle-services/procurement">
                  <LifecycleServicesProcurement />
              </Route>
              <Route path="/lifecycle-services/service-support">
                  <LifecycleServicesRepairs />
              </Route>
              <Route path="/lifecycle-services/asset-reclamation">
                  <LifecycleServicesEOL />
              </Route>
            </Switch>
          </Route>
          
          <Route path="/3d-printing">
            <ThreeD />
          </Route>
          
          <Route path="/terms">
            <Terms />
          </Route>
          
          <Route path="/edge-ai">
            <EdgeAI />
          </Route>
          
          {
            // Company
          }
          <Route path="/about">
            <About />
          </Route>
          
          <Route exact path="/careers">
            <Careers />
          </Route>
          
          <Route exact path="/news">
            <News />
          </Route>
          
          <Route path="/contact">
            <Contact />
          </Route>
          
          {
            // Thank You page
          }
          <Route path="/thank-you">
            <ThankYou />
          </Route>
          {
            // Landing Pages
          }
          
          <Route path="/digital-manufacturing">
            <DigitalManufacturing />
          </Route>
          
          {
            // Single post
          }
          
          <Route path="/news/:slug">
            <SinglePost />
          </Route>
          <Route path="/careers/:slug">
            <SingleJob />
          </Route>
          
          <Route path="**">
            <FourOhFour />
          </Route>
          
        </Switch>
        
        <Footer />
        
      </ScrollToTop> 
    </Router>
  );
}



export default App;

import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// Components

import PageHero from '../components/PageHero.scss';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


// Images

import InDemand from '../img/indemand-pba.svg'

// Partners
import Axis from '../img/partners/accucode-partners/axis-communications.png'
import Cisco from '../img/partners/accucode-partners/cisco-logo.png'
import Extreme from '../img/partners/accucode-partners/extreme-networks.png'
import Honeywell from '../img/partners/accucode-partners/honeywell-logo.png'
import Nokia from '../img/partners/accucode-partners/nokia.png'
import Panasonic from '../img/partners/accucode-partners/panasonic-logo.png'
import Verkada from '../img/partners/accucode-partners/verkada-logo.png'
import Zebra from '../img/partners/accucode-partners/zebra-logo.png'

// Video
import HeroBackground from "../vid/it-in-datacenter.mp4"
import Installation from "../vid/installing-network.mp4"

import '../components/LogoSlider.scss';
// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';


// CSS
import Styles from './LifecycleServices.scss';


function LifecycleServicesInstallations() {
  return (
    <>
      <Section className="installations ">
              <Container className="installations__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={5}>
                      <h3>Our installation services get your technology <span className="blue-text">up and running</span> in no time</h3>
                      
                      <h4>Nationwide footprint</h4>
                      <p>We have specialized technicians in every major city across North America ready to assist with your project.</p>
                      <h4>Site survey</h4>
                      <p>We’ll assess your location to determine site readiness and fix any issues we identify that could impact the installation process.</p>
                      
                      <h4>On-site installations</h4>
                      <p>Our techs are trained to finish installs quickly and with minimal disruptions to your business. We also offer training services where your IT team can learn to complete installations.</p>
        
                      <h4>Testing and validation</h4>
                      <p>Our techs never leave your side until each component has been tested and passes their inspection. Once the job is complete and secure, we have your back with 24/7 remote support.</p>
                   </Col>

                  
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 7}}>
                    <video className="video--sidebar"  loop autoPlay muted >
                      <source src={Installation} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              
              
            
        </Section>
    </>
  );
}

export default LifecycleServicesInstallations;

// Components

import { Link } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap';

import Section from '../components/Section'

import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

// CSS
import WFSCss from './4Work--WorkforceOptimizationSoftware.scss';


// Videos

import Using4work from '../vid/woman-using-4work.mp4'



// Images
//===========================
import TeltechMetrics from '../img/warehouse-dispatcher.jpg'
import TeltechApp from '../img/warehouse-drones.jpg'
import TeltechTakingPhoto from '../img/logistics-van.jpg'
import TeltechWarehouse from '../img/smart-warehouse.jpg'


function DmnFulfillment() {
  return (
    <div className="DmnFulfullment">
        <Section className="cctv light-grey-bg">
              <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={6}>
                    <Col xs={12} lg={10} >
                      <h3 className="">Outsourcing operational logistics enables you to focus on your business- from new product launches, to exploring new markets with less risks- leaving you with  <span className="blue-text">more time and resources.</span>  We want to understand and help your business on a day-to-day basis.</h3>
                    </Col>
                    <Row>
                      <Col xs={12} md={6}>
                        <h4>Cost reduction</h4>
                        <p>As an award-winning vendor-as-reseller for over 26 years with partners worldwide, we have the power to help you find the best pricing and technology based on volume and order frequency. This comes with our award-winning managed services that give you the freedom to invest in other key areas to grow and develop your business. Our InDemand lifecycle services work behind the scenes at every stage of technology rollouts for your customers as providers, software ISVs, hardware OEMs, or distributors. Our two operations depots located in Louisville, KY and Englewood, CO can handle any stage of an asset’s lifecycle from cradle to grave.</p>
                        <Link to="/lifecycle-services" className="rarr">See our lifecycle services</Link>
                      </Col>
                          
                      <Col xs={12} md={6}>
                        <h4>Scale up or down as needed</h4>
                        <p>Most businesses experience fluctuations in demand throughout the year now more than ever. We guide you in managing dips and gaps more effectively without having to commit to capital when you don’t need to. With growth and the need to scale, you don’t need to spend even more time on business from an operations standpoint and clunky IT infrastructure. For reverse logistics, leverage tailored software to automate and streamline even the most routine processes. Reducing our daily workload, and the ability to drive this process via ecosystem in app.</p>
                        
                        <Link to="/workforce-management-suite" className="rarr">Learn more about 4Work</Link>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Provide better customer experience</h4>
                        <p>Customers expect next-day or same-day shipping as standard. Using a managed services provider for a le carte services - from staging to repairs- gives you access to a larger distribution network ecosystem. Minimize downtime with preconfigured devices & next-day delivery. We can quickly service and deploy any amount of devices without errors. Our custom deployment services give our customers the ability to free up internal resources while we handle golden image development, staging & configuration, quality control, and packing & shipping.</p>
                        
                        <Link to="/lifecycle-services/service-support" className="rarr">See our support services</Link>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Mitigate risk</h4>
                        <p>Let us take the burden off you when unforeseen circumstances pop up for overnight replacements, on-site repairs, or fast additive manufacturing prototyping of parts to fulfill your orders as quickly as possible. Automated notifications and AI alert you to suggested maintenance or upgrades before problems even happen. We give your customers a single point of contact for all their service and repair needs. When a device breaks, customers simply open a ticket in our portal and send the broken device back to our depot or even on-site repairs. Our engineers are on stand-by and ready to help in our depots, in the 3D Printing Store, and in the field at every step of your project.</p>
                        <Link to="/3d-printing" className="rarr">See our 3D printing services</Link>
                      </Col>
                    </Row>
                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 6}}>
                    <video className="cctv__video video--sidebar"  loop autoPlay muted >
                      <source src={Using4work} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              
              
            
        </Section>
        
        
        
          <Section className="quote">
            <Row className="align-items-center">
              <Col className="quote__images quote__images--left" md={12} lg={4} xl={4}>
                <img src={TeltechMetrics} />
                <img src={TeltechTakingPhoto} />
              </Col>
              
              <Col className="quote__content" md={12} lg={4}  xl={4}>
                <p className="q__content h3 thin text-center">
                  Gain instant expertise and knowledge in the field. Every industry comes with major challenges of their own, so handing it off to the experts can really make a difference in the way you function - and it leaves you to focus on increasing your overall value to your customers.
                </p>
              </Col>
              
              <Col className="quote__images quote__images--right" md={12} lg={4} xl={4}>
                
                <img src={TeltechWarehouse} />
                <img src={TeltechApp} />
              </Col>
            </Row>
          </Section>
        
    </div>
  );
}

export default DmnFulfillment;

// Components

import { Link } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';

import Section from '../components/Section'

import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

// CSS
import Quote from '../components/Quote.scss';
import WFSCss from './4Work--WorkforceOptimizationSoftware.scss';


// Videos

import WarehouseWorker from '../vid/warehouse-worker-mobile.mp4'



// Images
//===========================
import TeltechMetrics from '../img/warehouse-dispatcher.jpg'
import TeltechApp from '../img/warehouse-drones.jpg'
import TeltechTakingPhoto from '../img/logistics-van.jpg'
import TeltechWarehouse from '../img/smart-warehouse.jpg'


function DmnProcessAutomation() {
  return (
    <div className="DmnProcessAutomation">
        <Section className="cctv light-grey-bg">
              <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={6}>
                    <Col xs={12} lg={10} >
                      <h3 className="">Automate processes, tracking operations in real time, and analyze  performance of any resources- technology, systems, workers, costs and more- to <span className="blue-text">make more informed supply chain decisions</span> in a fluctuating market without sacrificing downtime or quality.</h3>
                    </Col>
                    <Row>
                      <Col xs={12} md={6}>
                        <h4>Automation & visibility</h4>
                        <p>Business process automation platforms shouldn’t be a hassle to integrate- costing downtime and resources. 4Work is a unique software with a proven track record of predictable outcomes, flexibility, and affordable pricing so you can focus on your business goals. Get back control of your operations with real-time visibility anytime, anywhere in the depot or the field. For every project, tasks assignment, notifications, and data collection in-app are automated- ensuring that you stay ahead of potential errors, labor costs, or downtime that affect project timelines and fulfillment of unique contract requirements.</p>
                      </Col>
                          
                      <Col xs={12} md={6}>
                        <h4>No more emails & spreadsheets</h4>
                        <p>In a digital commerce engagement platform, you can create your own inventory ecosystem where you and your channel partners have a tool for transacting not only with customers, but with each other in app. Market fluctuations across the world make management of inventory levels at every site across your supply chain network very difficult to manage or prepare for. Order4Work is the order management automation tool that helps businesses understand needs at a glance, improve inventory efficiency, and allocate resources while centralizing operations.  
 </p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Decrease fulfillment time & cost</h4>
                        <p>Automating processes with software for distribution while storing inventory in multiple locations strategically reduces shipping costs and speeds up fulfillment timelines. Centralize logistics operations under one roof. Effortlessly track inventory and ensure that goods are stored, picked, packed, and shipped properly. provides visibility into inventory and order activity to ensure an effective fulfillment process. By improving inventory visibility and automating the order picking, packing and shipping, significantly reduce errors. </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 6}}>
                    <video className="cctv__video video--sidebar"  loop autoPlay muted >
                      <source src={WarehouseWorker} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              
              
            
        </Section>
        
        
        
          <Section className="quote">
            <Row className="align-items-center">
              <Col className="quote__images quote__images--left" md={12} lg={4} xl={4}>
                <img src={TeltechMetrics} />
                <img src={TeltechTakingPhoto} />
              </Col>
              
              <Col className="quote__content text-center" md={12} lg={4}  xl={4}>
                <p className="q__content h3 thin text-center">Execution is one of the greatest challenges of building an intelligent, automated business environment. You want to ensure all of your technology is optimized- integrating and sharing information properly throughout operations to final fulfillment, or you’ll hit even more roadblocks. You also want all of your workers to be able to utilize these new devices at peak capacity and with ease through task automation software systems.
                </p>
                
                <div>
                  <Link to="/contact"><Button className="mt-3">Schedule a demo</Button></Link>
                </div>
                <div className="mt-3">
                  <Link to="/workforce-management-suite" className="rarr">Learn more about 4Work</Link>
                </div>
              </Col>
              
              <Col className="quote__images quote__images--right" md={12} lg={4} xl={4}>
                
                <img src={TeltechWarehouse} />
                <img src={TeltechApp} />
              </Col>
            </Row>
          </Section>
        
    </div>
  );
}

export default DmnProcessAutomation;

import {Container, Row, Col } from 'react-bootstrap';
// Components
import Section from '../components/Section';

import './Hardware--Printing.scss';

// Video
import Printing from "../vid/label-printing.mp4"
function HardwarePrinting() {
  return (
    <div>
      <Section className="printing">
        <Container>
          <Row>
              <Col xs={{span:12, order:2}} lg={{span:6, order: 1}}>
                <h3 className="">Over <span className="blue-text">25 years</span> of proven success</h3>
                <p>When Accucode was founded over 25 years ago, we started as a VAR in barcode scanning and have learned what works and what doesn’t work along the way. We’ve acquired renown partners and handle integration with you at every point. Streamline sourcing and procurement so that you can focus on your business.</p>
                
                <Row>
                  <Col xs={12} md={6}>
                  
                    <h4>Think outside the box</h4>
                    <p>Our range of products as a legacy VAR comes with tailored guidance developed over years of experience. The same person that you start your journey with will be with you every step of the way.  </p>
                  </Col>
                  <Col xs={12} md={6}>
                    <h4>Added services & support</h4>
                    <p>Award-winning services from cradle to grave ensures that you have everything that you need right now, but always keeps your future in mind to set you up for success. </p>
                  </Col>
                  
                  <Col xs={12} md={6}>
                    <h4>Get it right from the start</h4>
                    <p>We carry mobile printers, industrial printers, desktop printers and tabletop printers, labels, tags, ribbons, parts and accessories from top brands that you can depend on. </p>
                  </Col>
                  
                  <Col xs={12} md={6}>
                    <h4>Unbox & go</h4>
                    <p>You need a complete printing solution the moment it arrives. Focus on your business and let us make technology easy and suited to your environment and project timelines. </p>
                  </Col>
                </Row>
                
              </Col>
              
              <Col xs={{span: 12, order:1}} lg={{span: 6, order:2}}>
                <video className="printing__video video--sidebar"  loop autoPlay muted >
                  <source src={Printing} type="video/mp4" />
                </video>
              </Col>
          </Row>
        </Container>
      </Section>
    </div>   
  );
}

export default HardwarePrinting

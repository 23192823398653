// CSS
import './ContentBox.scss';

function ContentBox(props) {
  return (
        <div className={"content-box " + props.className}>
            {props.children}  
        </div>
    );
}


export default ContentBox;
// Components

import { Link } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap';

import Section from '../components/Section'

import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

// CSS
// import WFSCss from './4Work--WorkforceOptimizationSoftware.scss';


// Videos

import Connectivity from '../vid/robots-in-warehouse.mp4'


// Images
//===========================
import TeltechMetrics from '../img/warehouse-dispatcher.jpg'
import TeltechApp from '../img/warehouse-drones.jpg'
import TeltechTakingPhoto from '../img/logistics-van.jpg'
import TeltechWarehouse from '../img/smart-warehouse.jpg'

function DmnDrones() {
  return (
    <div className="DmnDrones">
        <Section className="cctv light-grey-bg">
              <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={6}>
                    <Col xs={12} lg={10} >
                      <h3 className=""><span className="blue-text">Turnkey, cloud-driven solutions</span> for data collection and material handling in any business environment deliver proven increases in throughput, efficiency, and consistency, at lower costs</h3>
                    </Col>
                    <Row>
                      <Col xs={12} md={6}>
                        <h4>Logistics innovation</h4>
                        <p>Drones and other robotics add immense operation value by improving data collection accuracy, increasing productivity, and lowering labor costs. Highly skilled workers can focus on business goals rather than being bogged down by time-consuming, manual tasks. Our engineers can build, support, and manufacture parts with rapid prototyping and production manufacturing. We can help design and integrate a wide range of sensors and data collection tools to any platform. This includes RFID, bar code, visual cognition, and hundreds of environmental sensors. Our services team can provide comprehensive fulfillment, deployment, service and support, break-fix, and spare pool management. </p>
                        <Link className="rarr" to="/3d-printing">See our 3D printing services</Link>
                      </Col>
                          
                      <Col xs={12} md={6}>
                        <h4>Predictive analytics</h4>
                        <p>Artificial intelligence (AI) can transform your operations by analyzing and learning from data collected across devices in real time. Integrated AI transforms operations by predicting the future and making intelligent business decisions that get you one step ahead of any risk before a crisis. Combined technological solutions with engineering expertise are built for complete business automation that results in guiding workers in every step of every processes through AI.</p>
                        <Link className="rarr" to="/edge-ai">See our AI services</Link>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Cloud-based storage & security</h4>
                        <p>By storing data collected between multiple types of sensing devices in the cloud, you gain deep visibility and avoid silos in your business. Democratizing information within your organization makes it possible to focus on overall business goals and transform analytics into a competitive edge. By keeping track of historical data, real-time fluctuations, current operational outcomes, and other data points throughout your entire organization you can see real-time reporting anytime, anywhere.</p>
                        <Link className="rarr" to="/managed-networks">See our fully managed networks</Link>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>IoT connectivity</h4>
                        <p>Synchronizing and connecting sensors in fleets of drones and robotics to each other allows you to collect and share that information amongst these devices and feeds AI analytics and learning. Combined with operational technology, IoT connectivity creates an ecosystem to help regulate and monitor each step of a project. Expert insight and proven success with implementation prevents any downtime give you automated record updates of things that cost you labor, time, and money.</p>
                        <Link className="rarr" to="/contact">Get in touch</Link>
                      </Col>
                    </Row>
                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 6}}>
                    <video className="cctv__video video--sidebar"  loop autoPlay muted >
                      <source src={Connectivity} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              
              
            
        </Section>
        
        
        
          <Section className="quote">
            <Row className="align-items-center">
              <Col className="quote__images quote__images--left" md={12} lg={4} xl={4}>
                <img src={TeltechMetrics} />
                <img src={TeltechTakingPhoto} />
              </Col>
              
              <Col className="quote__content" md={12} lg={4}  xl={4}>
                <p className="q__content h3 thin text-center">
                  70% of organizations are planning to increase use of logistics technology in their warehouses in the next few years, according to a study by Zebra Technologies Warehouse Vision. In fact, businesses are expected to spend a total $2.5 billion for updated IoT hardware and installation.
                </p>
              </Col>
              
              <Col className="quote__images quote__images--right" md={12} lg={4} xl={4}>
                
                <img src={TeltechWarehouse} />
                <img src={TeltechApp} />
              </Col>
            </Row>
          </Section>
        
    </div>
  );
}

export default DmnDrones;


import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// API
import axios from 'axios';
import useFetch from '../utils/useFetch'
import apiFetch from '@wordpress/api-fetch';
import {Jobs, Posts} from '../utils/WordpressClient';

// Components
import PageHero from '../components/PageHero';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Icon from '../components/Icon';
import Quote from '../components/Quote';
import ContentBox from '../components/ContentBox';
import CountUp from 'react-countup';
import JobList from '../components/JobList';
import Separator from "../components/Separator"
// Images

import Global from '../img/us-from-space.webp'

import KevinPrice from '../img/kevinprice.webp'
import AccucodeWorker from '../img/accucode-worker.webp'
import AccucodeWorker2 from '../img/accucode-worker2.webp'
import AccucodeCubes from '../img/accucode-cubes.webp'

import QuoteIcon from '../img/quote.svg'
import AccucodeIcon from '../img/accucode-icon-white.svg'


// Partners
import Inc5000 from '../img/awards/inc-5000-color.webp'
import CRNSecurity from '../img/awards/crnsecurity.webp'
import Solution500 from '../img/awards/solution500.webp'
// import Swiper core and required modules
import { Autoplay, Navigation } from 'swiper';

// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
// Video
import HeroBackground from '../vid/smart-warehouse-2.mp4'
// Modal video
import YoutubeModal from 'react-youtube-modal'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';

// Styles
import Styles from './About.scss';

function About() {
  return (
    <div className="About">
      <HelmetComponent PageID="About" />
      <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xl={7}>
                  <Col className="page-hero__content " xl={10} >
                    <h1 className=" text-white"> <span className="bold">Continuous innovation </span> and <span className="bold">custom soutions</span></h1>
                    <Col lg={12} xl={10}>
                      <h3 className="thin text-white">What sets us apart as a technology company are our values, philosophies and unique approach to problem solving.</h3>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={HeroBackground} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
              <Col xl={5}>
                <Row>
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Visibility is where it starts</h6>
                    <Separator />
                    <p>With real-time, accurate visibility of people, materials and processes, we give you technology solutions aimed at driving automation, efficiency, and reliable outcomes.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6}md={6} xl={6}>
                    <h6>Customer engagement drives us</h6>
                    <Separator />
                    <p>Our goal is to engage with you from the beginning by truly understanding your business objectives and the unique challenges you face every day. </p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Integrity at every step</h6>
                    <Separator />
                    <p>We want to have an open and honest dialogue with our customers and partners at every step. We spend any time necessary to meet and develop custom solutions.</p>
                  </Col>
                  
                  <Col className="gy-5" sm={6} md={6} xl={6}>
                    <h6>Scalable & repeatable outcomes</h6>
                    <Separator />
                    <p>Identifying technological gaps and building tailored, self-sustaining solutions to fill them is what we do best to keep your business up and running.</p>
                  </Col>
                </Row>
       
              </Col>
            </Row>
          </Container>
        </Section>
        
        <Section className="partners light-grey-bg">
          <Container>
            <Row className="align-items-center">
               <Col sm={12} md={12} lg={4}>
                  <p className="caps">Awards</p>
                  <div className="separator"></div>
                  <h3>
                    <span className="blue-text">Award-winning</span> services and solutions 
                  </h3>
                </Col>
              <Col sm={12} md={{span:12 }}lg={{span: 8, offset: 0}}>
                  <Swiper
                    className="logo-slider logo-slider--color"
                    modules={[Autoplay, Navigation]}                   
                    breakpoints={{
                      // when window width is >= 0px
                      0: {
                        width: 320,
                        slidesPerView: 1,
                      },// when window width is >= 320px
                      320: {
                        width: 320,
                        slidesPerView: 2,
                      },// when window width is >= 720px
                      720: {
                        width: 720,
                        slidesPerView: 3,
                      },
                      
                    }}
                    spaceBetween={50}
                    slidesPerView={3}
                    autoplay
                    // navigation
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide><img src={Inc5000}/></SwiperSlide>
                    <SwiperSlide><img src={Solution500}/></SwiperSlide>
                    <SwiperSlide><img src={CRNSecurity}/></SwiperSlide>
                  </Swiper>
                  {
                    //
                    //<div className="swiper__nav">
                    //  <div className="swiper-button-prev"></div>
                    //  <div className="swiper-button-next"></div>
                    //</div>
                    //
                  }
              </Col>
            </Row>
          </Container>
        </Section>
        
        <Section className="quote">
          <Row className="align-items-center">
            <Col className="quote__images quote__images--left" md={12} lg={4} xl={4}>
              <img src={KevinPrice} />
              <img src={AccucodeWorker} />
            </Col>
            
            <Col className="quote__content" md={12} lg={4}  xl={4}>
              <img className="quote__icon" src={QuoteIcon} />
              <p className="q__content h3 thin text-center">
                Technology is always disrupting, so being a disruptor is part of who we are. We are the guys that come in and change whole industries through the application of technology.
              </p>
              <p className="text-center">Kevin Price | Founder & CEO</p>
               <YoutubeModal videoId="hRzeJ-_vq9E" width="1024" height="768" >
                  <button className="btn btn--primary btn--ghost">
                    Meet Accucode <FontAwesomeIcon icon="fa-regular fa-circle-play" />
                  </button>
                </YoutubeModal>
            </Col>
            
            <Col className="quote__images quote__images--right" md={12} lg={4} xl={4}>
              
              <img src={AccucodeWorker2} />
              <img src={AccucodeCubes} />
            </Col>
          </Row>
        </Section>
        
        <JobList />
      
        
    </div>
  );
}


export default About;

import HelmetComponent from "../components/HelmetComponent";
// Components

import { Link } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Section from '../components/Section'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


// Images

import ForWork from '../img/4work-logo-white.svg'

// Videos

import HeroBackground from '../vid/industrial-worker-with-phone.mp4'


// Tab content
import WOS from './4Work--WorkforceOptimizationSoftware'
import OMS from './4Work--OrderManagementSoftware'


import Styles from './4work.scss';

// Images
//===========================

function WorkforceOptimization() {
  return (
    <div className="WorkforceOptimization">
        <HelmetComponent PageID="ForWork" />
        <Section className="page-hero">
          <Container>
            <Row className="align-items-center">
              <Col className="page-hero__content-wrap " xs={12}>
                  <Col className="page-hero__content " lg={7} >
                    <img className="ForWork__logo" src={ForWork} />
                    <h1 className=" text-white">
                      <span className="bold">Automation and scalability for any project workflow</span> with complete fulfillment management
                    </h1>

                
                    <Col lg={12} xl={10}>
                      <Row>
                        <h3 className="thin text-white">The 4Work suite of digital conversion tools gives your business and your customers huge cost savings through visibility and collaboration.</h3>
                        </Row>
                        <Link className="" to="/contact"><Button variant="outline-light">Schedule a demo</Button></Link>

                    </Col>
                  </Col>
                  
                    <div className="page-hero__background">
                        <video className="page-hero__video"  loop autoPlay muted >
                          <source src={HeroBackground} type="video/mp4"/>
                        </video>
                        <div className="page-hero__background-color"></div>
       
                    </div>
              </Col>
              
            
            </Row>
          </Container>
        </Section>
        <Section className="light-grey-bg">
          <WOS />
      
        </Section>
        
    </div>
  );
}

export default WorkforceOptimization;

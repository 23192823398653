// Components

import { Link } from 'react-router-dom';
import {Container, Row, Col } from 'react-bootstrap';

import Section from '../components/Section'

import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

// CSS
import WFSCss from './4Work--WorkforceOptimizationSoftware.scss';


// Videos

import Using4work from '../vid/woman-using-4work.mp4'


// Images
//===========================
import TeltechMetrics from '../img/teltech__metrics.jpg'
import TeltechApp from '../img/teltech__app.jpg'
import TeltechTakingPhoto from '../img/teltech__takingphoto.jpg'
import TeltechWarehouse from '../img/Teltech__warehouse.jpg'

function WorkforceOptimization() {
  return (
    <div className="WorkforceOptimization">
        <Section className="cctv light-grey-bg">
              <Container className="cctv__content">
                <Row>
                  <Col xs={{span:12, order:2}}lg={6}>
                    <Col xs={12} lg={10} >
                      <h3 className="">Create a <span className="blue-text">collaborative workflow</span> of business process automation from order to invoice</h3>
                    </Col>
                    <Row>
                      <Col xs={12} md={6}>
                        <h4>Real-time visibility</h4>
                        <p>Gain insight into every IT rollout so you know where projects stand at any step of the process.</p>
                      </Col>
                          
                      <Col xs={12} md={6}>
                        <h4>Close the skills gap</h4>
                        <p>Give field techs and back office support the ability to achieve similar levels of results regardless of their experience or skill level.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Do more with less</h4>
                        <p>Scale your technology rollouts from hundreds to thousands of locations without needing to hire more field techs.</p>
                      </Col>
                      
                      <Col xs={12} md={6}>
                        <h4>Streamline processes</h4>
                        <p>Streamline delegation and tracking of complex tasks in the depot and in the field.</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 6}}>
                    <video className="cctv__video video--sidebar"  loop autoPlay muted >
                      <source src={Using4work} type="video/mp4" />
                    </video>
                  </Col>
                </Row>
              </Container>
              
              
            
        </Section>
        
        
        
          <Section className="quote">
            <Row className="align-items-center">
              <Col className="quote__images quote__images--left" md={12} lg={4} xl={4}>
                <img src={TeltechMetrics} />
                <img src={TeltechTakingPhoto} />
              </Col>
              
              <Col className="quote__content" md={12} lg={4}  xl={4}>
                <p className="q__content h3 thin text-center">
                  4Work allows you to design manageable, scalable and repeatable projects with real-time visibility and results you can count on. All of your project information-- from scheduling, labor, costs, asset details, and more-- in one place and updated in real time. 
                </p>
                
                <Link className="rarr quote__link" to="/news/4work-helps-teltechs-technology-asset-management-tam-2-0-platform-to-support-growth-initiatives">Read the success story</Link>
              </Col>
              
              <Col className="quote__images quote__images--right" md={12} lg={4} xl={4}>
                
                <img src={TeltechWarehouse} />
                <img src={TeltechApp} />
              </Col>
            </Row>
          </Section>
        
    </div>
  );
}

export default WorkforceOptimization;

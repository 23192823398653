import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// Components

import PageHero from '../components/PageHero.scss';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

// Images

import InDemand from '../img/indemand-pba.svg'

// Partners
import Axis from '../img/partners/accucode-partners/axis-communications.png'
import Cisco from '../img/partners/accucode-partners/cisco-logo.png'
import Extreme from '../img/partners/accucode-partners/extreme-networks.png'
import Honeywell from '../img/partners/accucode-partners/honeywell-logo.png'
import Nokia from '../img/partners/accucode-partners/nokia.png'
import Panasonic from '../img/partners/accucode-partners/panasonic-logo.png'
import Verkada from '../img/partners/accucode-partners/verkada-logo.png'
import Zebra from '../img/partners/accucode-partners/zebra-logo.png'

// Video
import HeroBackground from "../vid/it-in-datacenter.mp4"


// CSS
import Styles from './LifecycleServices.scss';

// Video
import Electrician from '../vid/electrician.mp4'


function LifecycleServicesRepairs() {
  return (
    <>
      <Section className="service">
        <Container className="service__content">
            <Row>
              <Col xs={{span:12, order:2}} lg={5}>
                <h3>Break-fix and <span className="blue-text">overnight replacement</span> your customers can count on</h3>
                        <h4>Single point of contact</h4>
                        <p>We give your customers a single address to ship to for all their service and repair needs. When a device breaks, customers simply open a ticket in our portal and send the broken device back to our depot for repairs.</p>
                        
                        <h4>On-site repairs</h4>
                        <p>Some technology repairs, like wireless networks, require on-site visits. We have hundreds of field techs across the country who quickly arrive on-site to assess and re-mediate any problems should something go wrong.</p>
  
                        <h4>Next-day replacements</h4>
                        <p>Customer spare pool devices are tracked by serial number and bin location within our operations depot. Each device is fully charged and configured for the assigned site, so if something breaks, we hot swap devices and immediately ship an overnight replacement to minimize employee downtime.</p>
                     
              </Col>
              <Col  xs={{span:12, order: 1}} lg={{span:6, order: 2}} xl={{span: 7}}>
                <video className="service__video video--sidebar"  loop autoPlay muted >
                  <source src={Electrician} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </Container>
                </Section>
    </>
  );
}

export default LifecycleServicesRepairs;

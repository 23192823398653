import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
// Components
import PageHero from '../components/PageHero';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';


import './Hardware--Printing.scss';

// Video
import VideoStyles from '../components/video.scss';
import Filament from "../vid/3d-filament-2.mp4"
function HardwarePrinting() {
  return (
    <div>
      <Section className="3dsupplies">
        <Container>
           <Row>
            <Col xs={12} sm={12} md={{span:12}} lg={6}>
              <Row className="align-items-center">
  
                <Col sm={12} md={{span:12 }} lg={12} xl={{span: 12}} >
                  <Row className="align-items-center">
                    <Col xs={12} lg={4} className="stat">
                      <CountUp
                        delay={0}
                        start={0}
                        end={100}
                        duration={2.75}
                        separator=" "
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix="+"
                        onEnd={() => console.log('Ended! 👏')}
                        onStart={() => console.log('Started! 💨')}
                      >
                        {({ countUpRef, start }) => (
                          <div>
                            <span  className="stat__number h2 blue-text" ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                      <h4 className="stat__context">Materials available</h4>
                    </Col>
                    
                    <Col xs={12} lg={4} className="stat">
                      <CountUp
                        delay={0}
                        start={0}
                        end={2000}
                        duration={2.75}
                        separator=" "
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix="+"
                        onEnd={() => console.log('Ended! 👏')}
                        onStart={() => console.log('Started! 💨')}
                      >
                        {({ countUpRef, start }) => (
                          <div>
                            <span  className="stat__number h2 blue-text" ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                      <h4 className="stat__context">Parts printed</h4>
                    </Col>
                    
                    <Col xs={12} lg={4} className="stat">
                      <CountUp
                        delay={0}
                        start={0}
                        end={1000}
                        duration={2.75}
                        separator=" "
                        decimals={0}
                        decimal=","
                        prefix=""
                        suffix="+"
                        onEnd={() => console.log('Ended! 👏')}
                        onStart={() => console.log('Started! 💨')}
                      >
                        {({ countUpRef, start }) => (
                          <div>
                            <span className="stat__number h2 blue-text" ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                      <h4 className="stat__context">Printers services</h4>
                    </Col>
                </Row>
              </Col>
            </Row>
            <hr/>
              <h3 className="">Additive manufacturing for <span className="blue-text">business and supply chain optimization</span></h3>
              <p>Explore new applications with reduced assembly counts from the ability to 3D print in multiple materials even within a single print. High-performance chemical and heat-resistant engineering materials are more and more in demand and need expertise. The 3D Printing Store powered by Accucode offers an ever growing catalog of materials and print technologies. Only the most reliable and cutting edge materials, scanning, and design capabilities check the boxes of our customer’s project requirements.</p>
              
            </Col>
          
            <Col xs={{span: 12}} sm={12} md={{span:12}} lg={6}>
              <video className="networking__video video--sidebar"  loop autoPlay muted >
                <source src={Filament} type="video/mp4" />
              </video>
            </Col>
          
          </Row>
            
        </Container>
      </Section>
    </div>   
  );
}

export default HardwarePrinting

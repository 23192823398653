import HelmetComponent from "../components/HelmetComponent";
import {Container, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
// Components
import PageHero from '../components/PageHero';
import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import Partners from '../components/PartnersSlider';

import CountUp from 'react-countup'
// import Icon from '../components/Icon';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../components/tabs.scss';

// Images
import AP from '../img/access-point.jpg'
import Switch from '../img/ethernet-switch.jpg'
import Router from '../img/router.jpg'
import LTE from '../img/lte.jpg'

// CSS
import Styles from './Hardware--Networking.scss'

// Video
import VideoStyles from '../components/video.scss';
import Networking from "../vid/networking.mp4"
function HardwareNetworking() {
  return (
     <>
    <Section className="networking">
        <Container>
          <Row>
              <Col xs={{span:12, order:2}} lg={{span:6, order: 1}}>
                <h3 className="">Having a <span className="blue-text">fast and reliable </span> network is essential to your success</h3>
                <p>With today's mobile driven environment, having a fast, secure, and reliable network is essential to your success. Your network is the backbone of your business, which is why we offer only the best-in-class networking devices that can support all your needs. Our network solutions range from wireless access points, wired switches, antennas, SDWAN solutions, security appliances, and more.</p>
                
                <Row>
                  <Col xs={12} md={6}>
                  
                    <h4>Access points</h4>
                    <p>Wireless Access Points, or WAPs, are a key tool when it comes to extending the scope and range of your network. Wireless access points can be installed in a number of variations and applications. Every time you add a wireless access point, both wired and POE (power over ethernet), to your network (indoor or outdoor), you extend the range and number of devices your network can support. Let us help define what is best for your business!   </p>
                  </Col>
                  <Col xs={12} md={6}>
                    <h4>Ethernet switches</h4>
                    <p>The role of an Ethernet switch is to provide the means for various components such as computers, printers, and IoT devices to communicate with one another. These devices connect various elements of an enterprise network, providing effective performance and efficient use of the resources linked to the system. If you’re not too familiar with switching, connect with us to learn what's available and what's best for your business.    </p>
                  </Col>
                  
                  <Col xs={12} md={6}>
                    <h4>Security appliances & routers</h4>
                    <p>In some cases, large amounts of data may need to move across a network within an organization. Specialized tools (routers) focus on transferring these massive amounts of information as fast as possible. Routers can be used for a wide range of purposes, both public and private. While some routers must be easily accessible, others are designed specifically for security and surveillance purposes. Security routers offer enhanced security and surveillance features.</p>
                  </Col>
                  
                  <Col xs={12} md={6}>
                  <h4>Private LTE</h4>
                  <p>Private LTE is an agile, secure, and resilient way to accelerate your digital transformation with a proven on-premise edge solution and industrial-grade private wireless connectivity. Digital Automation Cloud (DAC) is a high-performance, end-to-end private wireless networking and edge computing platform. DAC combines reliable high-bandwidth, low latency 4G/5G connectivity with local edge computing capabilities and a catalog of click & deploy applications. </p>
                  </Col>
                </Row>
                
              </Col>
              
              <Col xs={{span: 12, order:1}} lg={{span: 6, order:2}}>
                <video className="networking__video"  loop autoPlay muted >
                  <source src={Networking} type="video/mp4" />
                </video>
              </Col>
          </Row>
        </Container>
      </Section>
      </> 
  );
}

export default HardwareNetworking
